import { ISvg } from "../interfaces/ISvg";

function SpekaIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 12.25C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75H15C15.4142 13.75 15.75 13.4142 15.75 13C15.75 12.5858 15.4142 12.25 15 12.25H9Z" 
      fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18 2.25C18.9665 2.25 19.75 3.0335 19.75 4V20C19.75 20.9665 18.9665 21.75 18 21.75H6C5.0335 21.75 4.25 20.9665 4.25 20V4C4.25 3.0335 5.0335 2.25 6 2.25H18ZM5.75 4C5.75 3.86193 5.86193 3.75 6 3.75H8.25V6C8.25 6.46413 8.43437 6.90925 8.76256 7.23744C9.09075 7.56563 9.53587 7.75 10 7.75H14C14.4641 7.75 14.9092 7.56563 15.2374 7.23744C15.5656 6.90925 15.75 6.46413 15.75 6V3.75H18C18.1381 3.75 18.25 3.86193 18.25 4V20C18.25 20.1381 18.1381 20.25 18 20.25H6C5.86193 20.25 5.75 20.1381 5.75 20V4ZM9.75 3.75H14.25V6C14.25 6.0663 14.2237 6.12989 14.1768 6.17678C14.1299 6.22366 14.0663 6.25 14 6.25H10C9.9337 6.25 9.87011 6.22366 9.82322 6.17678C9.77634 6.12989 9.75 6.0663 9.75 6V3.75Z" 
      fill="currentColor" />
      <path d="M8.25 17C8.25 16.5858 8.58579 16.25 9 16.25H15C15.4142 16.25 15.75 16.5858 15.75 17C15.75 17.4142 15.4142 17.75 15 17.75H9C8.58579 17.75 8.25 17.4142 8.25 17Z"
      fill="currentColor" />
    </svg>
  );
}

export default SpekaIcon;