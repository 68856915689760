import { ISvg } from "../interfaces/ISvg";

function DownloadIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V15.1893L9.53033 13.4697C9.23744 13.1768 8.76256 13.1768 8.46967 13.4697C8.17678 13.7626 8.17678 14.2374 8.46967 14.5303L11.4697 17.5303C11.6135 17.6741 11.7966 17.75 12 17.75C12.1908 17.75 12.3954 17.6652 12.5303 17.5303L15.5303 14.5303C15.8232 14.2374 15.8232 13.7626 15.5303 13.4697C15.2374 13.1768 14.7626 13.1768 14.4697 13.4697L12.75 15.1893V3Z" 
      fill="currentColor" />
      <path d="M4 16.25C4.41421 16.25 4.75 16.5858 4.75 17V20C4.75 20.0663 4.77634 20.1299 4.82322 20.1768C4.87011 20.2237 4.93369 20.25 5 20.25H19C19.0663 20.25 19.1299 20.2237 19.1768 20.1768C19.2237 20.1299 19.25 20.0663 19.25 20V17C19.25 16.5858 19.5858 16.25 20 16.25C20.4142 16.25 20.75 16.5858 20.75 17V20C20.75 20.4641 20.5656 20.9092 20.2374 21.2374C19.9092 21.5656 19.4641 21.75 19 21.75H5C4.53587 21.75 4.09075 21.5656 3.76256 21.2374C3.43437 20.9092 3.25 20.4641 3.25 20V17C3.25 16.5858 3.58579 16.25 4 16.25Z" 
      fill="currentColor" />
    </svg>
  );
}

export default DownloadIcon;