import { FC, useEffect, useState } from "react";
import ArrowLeftIcon from "../../../../assets/icons/ArrowLeftIcon";
import LinkButton from "../../../UI/buttons/LinkButton";
import TextBoxDefault from "../../../UI/inputs/textInput/TextBoxDefault";
import LogOutIcon from "../../../../assets/icons/LogOutIcon";
import Button from "../../../UI/buttons/Button";
import LockIcon from "../../../../assets/icons/LockIcon";
import Select from "../../../UI/inputs/dropdown/Select";
import { ChangeProfileRequest, getProfileResponse } from "../../../../API/base/profileApi/profileInterfaces";
import { useNavigate } from "react-router-dom";
import ProfileEmailField from "./components/ProfileEmailField";
import ModalProfilePasswordChangeModal from "./components/modals/ModalProfilePasswordChangeModal";
import { ChangeProfileInfo } from "../../../../API/base/profileApi/profileFunctions";
import { ResponseContainer } from "../../../../API/BaseApi";
import { GetPayload } from "../../../../API/base/payloadApi/payloadFunctions";
import { GetPayloadResponse, PaylodParam } from "../../../../API/base/payloadApi/payloadInterfaces";
import { toast } from "react-toastify";
import Loader from "../../../UI/loader/loader";
import LoaderModal from "../../../UI/loader/LoaderModal";
import { checkInn, checkIsNumber } from "../../../../utils/validators";

interface IProps {
  onLogout: () => void,
  profileInfo: getProfileResponse | undefined
  onRefreshProfile: () => void
}
const requiredFields: { [key in keyof Omit<ChangeProfileRequest, 'city'>]: string } = {
  companyName: "Наименование организации",
  firstName: "Имя",
  inn: "ИНН",
  lastName: "Фамилия",
  specializationId: "Классификатор партнера",
};

const ProfilePage: FC<IProps> = ({
  onLogout,
  profileInfo,
  onRefreshProfile
}) => {
  const navigate = useNavigate()
  const [updateUserInfo, setUpdateUserInfo] = useState<ChangeProfileRequest | undefined>(profileInfo)
  const [partnerTypes, setPartnerTypes] = useState<Array<PaylodParam>>([])
  const [isProfileChanged, setIsProfileChanged] = useState<boolean>(false)
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false)
  const [innError, setInnError] = useState<boolean>(false)
  const [changeLoading, setChangeLoading] = useState<boolean>(false)

  useEffect(() => {
    onGetPayload()
  }, [profileInfo])

  const onGetPayload = () => {
    GetPayload({ specializations: true }).then((r: ResponseContainer<GetPayloadResponse>) => {
      setPartnerTypes(r.data?.specializations || [])
    })
  }

  useEffect(() => {
    if (profileInfo && updateUserInfo) {
      const isChanged = JSON.stringify(profileInfo) !== JSON.stringify(updateUserInfo);
      setIsProfileChanged(isChanged);
    }
  }, [profileInfo, updateUserInfo])

  const handleSaveProfile = () => {
    if (!updateUserInfo) {
      return;
    }
    const data: ChangeProfileRequest = {
      city: updateUserInfo.city,
      companyName: updateUserInfo.companyName,
      firstName: updateUserInfo.firstName,
      inn: updateUserInfo.inn,
      lastName: updateUserInfo.lastName,
      specializationId: updateUserInfo.specializationId,
    };
    for (const key in requiredFields) {
      if (requiredFields.hasOwnProperty(key) && !data[key as keyof Omit<ChangeProfileRequest, 'city'>]) {
        toast.error(`${requiredFields[key as keyof Omit<ChangeProfileRequest, 'city'>]} должно быть заполнено.`);
        return;
      }
    }
    if (innError) {
      toast.error('Необходимо ввести корректный ИНН')
      return
    }
    setChangeLoading(true)
    ChangeProfileInfo(data).then((r) => {
      setChangeLoading(false)
      if (r.status === "error") {
        toast.error("Ошибка при сохранении")
        return;
      }
      onRefreshProfile()
      toast.success("Данные профиля сохранены")
    });

  };

  const onSetInn = (innText: string) => {
    if (checkIsNumber(innText)) {
      if (checkInn(innText)) {
        setInnError(false)
      }
      else {
        setInnError(true)
      }
      if (updateUserInfo) {
        setUpdateUserInfo({ ...updateUserInfo, inn: innText })
      }
    }
  }

  return (
    <div className="h-[calc(100%-76px)]">
      {updateUserInfo ? (
        <div className="h-[90vh] flex flex-col justify-between px-[284px] mt-6">
          <div>
            <div className="flex items-center gap-2 -ml-10">
              <span className="w-8 h-8 bg-gray-1 flex items-center justify-center rounded-lg text-accent cursor-pointer 
              hover:text-accent-dark hover:bg-gray-2 transition-all"
                onClick={() => { navigate(-1) }}>
                <ArrowLeftIcon size={16} />
              </span>
              <h1 className="text-2xl font-semibold">Профиль</h1>
            </div>
            <ProfileEmailField userEmail={profileInfo?.email} />
            <div className="pb-6 border-b border-gray-2">
              <div className="flex items-center gap-6 mb-6 mt-5">
                <TextBoxDefault onChange={(e) => { setUpdateUserInfo({ ...updateUserInfo, firstName: e.target.value }) }} value={updateUserInfo.firstName} label="Имя" className="!w-[424px]" />
                <TextBoxDefault onChange={(e) => { setUpdateUserInfo({ ...updateUserInfo, lastName: e.target.value }) }} value={updateUserInfo.lastName} label="Фамилия" className="!w-[424px]" />
              </div>
              <div className="flex items-center gap-6 mb-6">
                <TextBoxDefault onChange={(e) => { setUpdateUserInfo({ ...updateUserInfo, city: e.target.value }) }} value={updateUserInfo.city} label="Город" className="!w-[424px]" />
                <TextBoxDefault
                  onChange={(e) => { onSetInn(e.target.value) }}
                  value={updateUserInfo.inn}
                  error={innError}
                  description="Некорректный ИНН"
                  label="ИНН"
                  className="!w-[424px]" />
              </div>
              <div className="flex items-center gap-6 mb-6">
                <TextBoxDefault onChange={(e) => { setUpdateUserInfo({ ...updateUserInfo, companyName: e.target.value }) }} value={updateUserInfo.companyName} label="Наименование организации" className="!w-[424px]" />
                <Select
                  className="!w-[424px]"
                  onValueChange={(specializationId: string) => {
                    setUpdateUserInfo({ ...updateUserInfo, specializationId: specializationId });
                  }}
                  value={updateUserInfo.specializationId}
                  items={partnerTypes}
                  getItemValue={(item) => item.id}
                  getItemTitle={(item) => item.name}
                  label="Классификатор партнера"
                  placeholder="Выберите классификатор"
                />
              </div>
            </div>
            <LinkButton onClick={onLogout} className="text-gray-3 hover:text-accent transition-all mt-6" noFish><LogOutIcon size={20} />Выйти из профиля</LinkButton>
          </div>
          <div className="py-4 flex items-center justify-between border-t border-gray-2">
            <Button disabled={!isProfileChanged} onClick={handleSaveProfile} className="w-fit" rounded="small" noFish>Сохранить</Button>
            <LinkButton onClick={() => { setOpenPasswordModal(true) }} noFish><LockIcon size={20} /> Изменить пароль</LinkButton>
          </div>
        </div>
      ) : <Loader />}
      <ModalProfilePasswordChangeModal
        onOpenChange={setOpenPasswordModal}
        open={openPasswordModal}
      />
      <LoaderModal open={changeLoading} />
    </div>
  );
}

export default ProfilePage;
