import { ISvg } from "../interfaces/ISvg";

function BasketIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.49987 21.75C9.32829 21.75 9.99987 21.0784 9.99987 20.25C9.99987 19.4216 9.32829 18.75 8.49987 18.75C7.67144 18.75 6.99987 19.4216 6.99987 20.25C6.99987 21.0784 7.67144 21.75 8.49987 21.75Z" 
      fill="currentColor" />
      <path d="M18.2499 21.75C19.0783 21.75 19.7499 21.0784 19.7499 20.25C19.7499 19.4216 19.0783 18.75 18.2499 18.75C17.4214 18.75 16.7499 19.4216 16.7499 20.25C16.7499 21.0784 17.4214 21.75 18.2499 21.75Z" 
      fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.32454 2.25C3.98559 2.24952 4.58603 2.70191 4.76782 3.3375L5.52914 6H21.7842C22.2745 6 22.6401 6.48465 22.5054 6.95604L20.0313 15.6153L20.0307 15.6174C19.7622 16.5695 18.8527 17.2547 17.8635 17.25L17.8611 17.25L17.8655 17.25H17.8635H8.88624L8.8886 17.25L8.88424 17.25H8.88624M6.71844 15.6153L6.71896 15.6172L6.71844 15.6153ZM6.71844 15.6153L3.32566 3.75L1.74987 3.75C0.782597 3.75 0.782597 2.25 1.74987 2.25H3.32454M5.95805 7.5L8.16256 15.2097C8.24784 15.5133 8.56451 15.7518 8.87988 15.75L8.88424 15.75L17.8655 15.75L17.8698 15.75C18.1852 15.7518 18.5019 15.5133 18.5872 15.2097L18.5881 15.2065L20.7899 7.5H5.95805Z" 
      fill="currentColor" />
      <path d="M8.88424 17.25H8.88624C7.03234 12.25 5.17844 7.25 3.32454 2.25H1.74987C0.782597 2.25 0.782597 3.75 1.74987 3.75L3.32566 3.75L6.71844 15.6153L6.71896 15.6172C6.8793 16.1614 7.53683 17.25 8.88424 17.25Z" 
      fill="currentColor" />
    </svg>
  );
}

export default BasketIcon;