import clsx from "clsx";
import { forwardRef, InputHTMLAttributes, ReactNode, useEffect, useState } from "react";
import SearchIcon from "../../../../assets/icons/SearchIcon";
import CrossIcon from "../../../../assets/icons/CrossIcon";
import { getRandomId } from "../../../../utils/reusedFunks";

export type inputSize = "big" | "small";

const sizeClasses = {
	big: "h-12",
	small: "h-9",
};

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	inputSize?: inputSize;
	onSearchDebounced?(value: string): void;
	debounceMs?: number;
	resultLabel?: ReactNode;
	label?: ReactNode;
	onClear?(): void;
	onChangeText?(text: string): void;
	blurIgnore?: boolean
	unCleanable?: boolean,
	parentControl?: boolean,
	value?: string;
	error?: boolean;

}

const SearchBox = forwardRef<HTMLDivElement, Props>(
	(
		{
			inputSize = "big",
			onSearchDebounced,
			debounceMs = 500,
			resultLabel,
			label,
			className,
			onChangeText,
			onClear,
			blurIgnore,
			unCleanable,
			parentControl,
			value,
			error,
			...props
		}: Props,
		ref,
	) => {
		const [search, setSearch] = useState<string>(!parentControl ? "" : value as string);
		const [searchFocus, setSearchFocus] = useState<boolean>(false)

		useEffect(() => {
			onChangeText?.(search)
			if (onClear && search === "") {
				onClear()
				return
			}
			if (!onSearchDebounced) return;
			if (searchFocus || blurIgnore) {
				const getData = setTimeout(() => {
					onSearchDebounced(search);
				}, debounceMs);
				return () => clearTimeout(getData);
			}
			if (search === "") {
				onClear?.()
			}
		}, [search]);

		useEffect(() => {
			if (parentControl && value) {
				setSearch(value)
			}
		}, [value])

		const id = getRandomId("sb");
		return (
			<div ref={ref} className="relative w-full">
				<div className="absolute left-3 top-1/2 my-auto -translate-y-1/2">
					<SearchIcon size={20} className="text-gray-3" />
				</div>
				<input
					className={clsx(
						`w-full rounded-lg border ${!error ? "border-gray-2" : "border-system-red"} px-3 pl-10 text-sm outline-none disabled:bg-gray-1`,
						sizeClasses[inputSize],
						className,
					)}
					onFocus={() => { setSearchFocus(true) }}
					onBlur={() => {
						setTimeout(() => setSearchFocus(false), 100)
					}}
					id={id}
					value={!parentControl ? search : value}
					onChange={(e) => setSearch(e.target.value)}
					{...props}
				/>
				{!unCleanable && <div onClick={() => {
					setSearch("")
				}} className="absolute right-3 top-1/2 my-auto -translate-y-1/2 cursor-pointer">
					{search && <CrossIcon size={20} className="text-gray-3 hover:text-accent" />}
				</div>}

				{label && <div onClick={() => {
					setSearch("")
				}} className="absolute right-3 top-1/2 my-auto -translate-y-1/2 cursor-pointer">
					{label}
				</div>}


				{searchFocus && <div onClick={(e) => e.stopPropagation} className="absolute z-30 left-0 top-14 rounded-lg overflow-hidden bg-white w-full max-h-[330px] shadow-1">
					{resultLabel}
				</div>}
			</div>
		);
	},
);

SearchBox.displayName = "SearchBox";

export default SearchBox;
