import { FC } from "react";
import clsx from "clsx";
import { IReference } from "../../../../../../assets/interfaces/IReference";

interface OrderHistoryItemProps {
  refs: IReference[]
}

const OrderHistoryItem: FC<OrderHistoryItemProps> = ({ refs }) => {
  const itemsList = refs.map((i: IReference, index: number) => (
    <tr key={index} className="">
      <td className="border border-gray-1 p-2">
        <div className="text-xs">{i.referenceSku}</div>
      </td>
      <td className="border align-top border-gray-1 p-2 text-xs font-normal">
        {i.desc}
      </td>
      <td className={clsx("border w-[123px] border-gray-1 px-2")}>
        <div className="py-2 text-xs font-normal ">{i.count}</div>
      </td>
    </tr>
  ));

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr className="select-none">
            <th className="font-semibold text-left  text-xs p-2">
              Референс
            </th>
            <th className="font-semibold text-left  text-xs p-2">
              Название
            </th>
            <th className="font-semibold text-left text-xs p-2">
              Количество
            </th>
          </tr>
        </thead>
        <tbody>
          {itemsList}
        </tbody>
      </table>
    </div>
  );
}

export default OrderHistoryItem;
