import { ISvg } from "../interfaces/ISvg";

function MinusIcon({size, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 12C4 11.5858 4.34662 11.25 4.77419 11.25H19.2258C19.6534 11.25 20 11.5858 20 12C20 12.4142 19.6534 12.75 19.2258 12.75H4.77419C4.34662 12.75 4 12.4142 4 12Z" 
      fill="currentColor" />
    </svg>
  );
}

export default MinusIcon;