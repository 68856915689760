import clsx from "clsx";
import {ReactNode, useEffect, useState} from "react";

import DropdownBase from "../dropdown/DropdownBase";
import ScrollArea from "../../wrappers/ScrollArea";
import Checkbox from "../checkBox/CheckBox";
import SearchBox from "../textInput/SearchBox";
import systemeIcon from "../../../../assets/systemeIcon.svg"
interface Props<T> {
	children: ReactNode;
	notChild?: boolean;
	align?: "center" | "end" | "start" | undefined;

	onOpenChange?(value: boolean): void;
	searchable?: boolean;
	pickedValues: (string | number | boolean)[];
	items: T[];

	getItemTitle(item: T): string;
	getItemValue(item: T): string | number | boolean;
	getItemImg?(item: T): string;

	onCheck?(item: T): void;

	styleClasses?: {
		content?: string;
		item?: string;
		arrow?: string;
		scroll?: string;
        search?: string;
		container?: string;
	};
}

const FilterDropdown = <T extends object>(props: Props<T>) => {
	const [items, setItems] = useState(props.items);
	const [search, setSearch] = useState("");

	useEffect(() => {
		if (search === "") {
			return setItems(props.items);
		} else {
			setItems(
				props.items.filter((item) =>
					props.getItemTitle(item).toLowerCase().includes(search.toLowerCase()),
				),
			);
		}
	}, [props.items, search]);

	return (
		<DropdownBase
			align="start"
			styleClasses={{
				content: clsx(
					"w-[--radix-dropdown-menu-trigger-width]",
					"h-auto max-h-[15rem] min-w-[190px]", props.styleClasses?.container,
				),
			}}
			trigger={props.children}
			notChild
			onOpenChange={props.onOpenChange}
		>
			{props.searchable ? (
				<div className="mb-2 mt-1 flex px-3">
					<SearchBox 
                        className={props.styleClasses?.search}
						debounceMs={100}
						inputSize="small"
						placeholder="Поиск"
						onSearchDebounced={setSearch}
					/>
				</div>
			) : null}
			<ScrollArea className={props.styleClasses?.scroll}>
				{items.map((item) => (
					<div
						key={`ddm-${props.getItemTitle(item)}`}
						className={clsx(
							"flex select-none items-center px-3 py-1 outline-none hover:bg-gray-1 !w-full",
							"data-[disabled]:pointer-events-none",
							props.styleClasses?.item,
						)}
					>
						<Checkbox
							image={props.getItemImg && (props.getItemImg(item) || systemeIcon)}
							checked = {props.pickedValues.includes(props.getItemTitle(item))}
							label={props.getItemTitle(item)}
							styleClasses={{
								container: "gap-3",
							}}
							onCheckedChange={() => props.onCheck?.(item)}
						/>
					</div>
				))}
			</ScrollArea>
		</DropdownBase>
	);
};

export default FilterDropdown;