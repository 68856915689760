import { FC, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import { useState } from "react";
import 'react-day-picker/dist/style.css';
import { ru } from 'date-fns/locale';
import { format } from 'date-fns';

interface IProps {
  selectDate: (date: Date) => void
}

const Calendar: FC<IProps> = ({ selectDate }) => {
  const [selected, setSelected] = useState<Date | undefined>();
  const [selectedToday, toggleSelectedToday] = useState<boolean>(false)
  useEffect(() => {
    if (selected) {
      const today = new Date();
      toggleSelectedToday(format(today, "PPP") === format(selected as Date, "PPP"))
      selectDate(selected)
    }
  }, [selected])
  return (<div className="bg-white rounded-lg shadow-2 select-none">
    <DayPicker mode="single"
      styles={{
        button: {
          borderRadius: '8px',
          margin: '2px'
        },
        head: {
          color: "#969FA8",
        },
        nav: {
          color: "#969FA8"
        },
        caption: {
          borderBottom: "1px solid #F5F5F5"
        }
      }}
      modifiersClassNames={{
        selected: "!bg-accent !text-white",
        today: !selectedToday ? `${"!bg-white !border-accent !text-accent !font-semibold"}` : "!bg-accent !text-white",
      }}
      locale={ru}
      selected={selected}
      onSelect={setSelected}
      showOutsideDays />
  </div>);
}

export default Calendar;