import { ISvg } from "../interfaces/ISvg";

function UploadIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.53033 6.53033C9.23744 6.82322 8.76256 6.82322 8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967L11.4697 2.46967C11.6155 2.3238 11.8066 2.25057 11.9978 2.25L12 2.25L12.0022 2.25C12.2047 2.2506 12.387 2.32639 12.5303 2.46967L15.5303 5.46967C15.8232 5.76256 15.8232 6.23744 15.5303 6.53033C15.2374 6.82322 14.7626 6.82322 14.4697 6.53033L12.75 4.81066V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V4.81066L9.53033 6.53033Z" 
      fill="currentColor" />
      <path d="M4 16.25C4.41421 16.25 4.75 16.5858 4.75 17V20C4.75 20.0663 4.77634 20.1299 4.82322 20.1768C4.87011 20.2237 4.93369 20.25 5 20.25H19C19.0663 20.25 19.1299 20.2237 19.1768 20.1768C19.2237 20.1299 19.25 20.0663 19.25 20V17C19.25 16.5858 19.5858 16.25 20 16.25C20.4142 16.25 20.75 16.5858 20.75 17V20C20.75 20.4641 20.5656 20.9092 20.2374 21.2374C19.9092 21.5656 19.4641 21.75 19 21.75H5C4.53587 21.75 4.09075 21.5656 3.76256 21.2374C3.43437 20.9092 3.25 20.4641 3.25 20V17C3.25 16.5858 3.58579 16.25 4 16.25Z" 
      fill="currentColor" />
    </svg>
  );
}

export default UploadIcon;