import { P, getCurrentUrl, requestDelete, requestPost, requestPut } from "../../requester";
import { CreateAdminRequest, EditAdminRequest, GetAdminsRequest, GetAdminsResponse } from "./adminsInterfaces";

export function GetAdmins(args: GetAdminsRequest) {
    return requestPost<GetAdminsResponse>(P.Admins, args)
}

export function EditAdmin(adminId: string, args: EditAdminRequest) {
    const url = getCurrentUrl(P.Admin, adminId)
    return requestPut<null>(url, args)
}

export function CreateAdmin(args: CreateAdminRequest) {
    const currentUrl = `/${P.Admin.split("/")[1]}/${P.Admin.split("/")[2]}`
    return requestPost<null>(currentUrl, args)
}

export function DeleteAdmin(adminId: string) {
    const url = getCurrentUrl(P.Admin, adminId)
    return requestDelete(url)
}