import * as ScrollAreaBase from "@radix-ui/react-scroll-area";
import clsx from "clsx";
import React from "react";

interface Props {
	children: React.ReactNode;
	className?: string;
	enableHorizontal?: boolean;
	disableVertical?: boolean;
	onScrollEnd?(): void;
	id?: string;
}

const ScrollArea = (props: Props) => {

	const onScroll = (e:any) => {
		if(e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
			props.onScrollEnd?.()
		}
	}

	return (
		<ScrollAreaBase.Root className="flex h-full w-full overflow-hidden" type="auto">
			<ScrollAreaBase.Viewport id={props.id} onScroll={(e) => props.onScrollEnd && onScroll(e)} className={clsx("h-full w-full", props.className)}>
				{props.children}
			</ScrollAreaBase.Viewport>
			{!props.disableVertical && (
				<ScrollAreaBase.Scrollbar
					className={clsx(
						"flex touch-none select-none p-1 transition-colors ease-out",
						"data-[orientation=vertical]:w-3",
					)}
					orientation="vertical"
				>
					<ScrollAreaBase.Thumb
						className={clsx(
							"relative flex-1 rounded-[10px] bg-gray-2 p-[px]",
							"before:absolute before:left-1/2 before:top-1/2",
							"before:h-full before:min-h-[1rem] before:w-full before:min-w-[1rem]",
							'before:-translate-x-1/2 before:-translate-y-1/2 before:content-[""]',
						)}
					/>
				</ScrollAreaBase.Scrollbar>
			)}
			{props.enableHorizontal ? (
				<ScrollAreaBase.Scrollbar
					className={clsx(
						"flex touch-none select-none p-1 transition-colors ease-out",
						"data-[orientation=horizontal]:h-3 data-[orientation=horizontal]:flex-col",
					)}
					orientation="horizontal"
				>
					<ScrollAreaBase.Thumb
						className={clsx(
							"relative flex-1 rounded-[10px] bg-gray-1 p-[1px]",
							"before:absolute before:left-1/2 before:top-1/2",
							"before:h-full before:min-h-[1rem] before:w-full before:min-w-[1rem]",
							'before:-translate-x-1/2 before:-translate-y-1/2 before:content-[""]',
						)}
					/>
				</ScrollAreaBase.Scrollbar>
			) : null}
		</ScrollAreaBase.Root>
	);
};

export default ScrollArea;
