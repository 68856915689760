import {P, requestPost, requestPostForm} from "../../requester";
import {UserAuthRequest} from "./authRegInterfaces";
import {RegistrationRequest, VerifiConfirmRequest} from "./authRegInterfaces";

export function Login(args: UserAuthRequest) {
	return requestPostForm<null>(P.Login, args);
}

export function Registrarion(args: RegistrationRequest) {
	return requestPost<null>(P.Registration, args);
}

export function Verification(email: string, isRecovery?: boolean) {
	const data: any = {email: email} 
	if (isRecovery) data.isRecovery = isRecovery
	return requestPost<null>(P.Verification, data);
}

export function VerifiConfirm(args: VerifiConfirmRequest) {
	return requestPost<null>(P.VerifiConfirm, args);
}

export function ResetPassword(newPassword: string, email: string) {
	return requestPost<null>(P.ResetPassword, {newPassword: newPassword, email: email});
}

export function LogOut() {
	return requestPost<null>(P.Logout)
}
