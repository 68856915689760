import { ISvg } from "../interfaces/ISvg";

function TrashIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.75 9.75C10.1642 9.75 10.5 10.0858 10.5 10.5V16.5C10.5 16.9142 10.1642 17.25 9.75 17.25C9.33579 17.25 9 16.9142 9 16.5V10.5C9 10.0858 9.33579 9.75 9.75 9.75Z" 
      fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.90901 2.65901C8.33097 2.23705 8.90326 2 9.5 2H14C14.5967 2 15.169 2.23705 15.591 2.65901C16.0129 3.08097 16.25 3.65326 16.25 4.25V5.25H20.25C20.6642 5.25 21 5.58579 21 6C21 6.41421 20.6642 6.75 20.25 6.75H19.5V20.25C19.5 20.6478 19.342 21.0294 19.0607 21.3107C18.7794 21.592 18.3978 21.75 18 21.75H6C5.60218 21.75 5.22065 21.592 4.93934 21.3107C4.65804 21.0294 4.5 20.6478 4.5 20.25V6.75H3.75C3.33579 6.75 3 6.41421 3 6C3 5.58579 3.33579 5.25 3.75 5.25H7.25V4.25C7.25 3.65326 7.48705 3.08097 7.90901 2.65901ZM14.75 4.25V5.25H8.75V4.25C8.75 4.05109 8.82902 3.86032 8.96967 3.71967C9.11032 3.57902 9.30109 3.5 9.5 3.5H14C14.1989 3.5 14.3897 3.57902 14.5303 3.71967C14.671 3.86032 14.75 4.05109 14.75 4.25ZM6 20.25V6.75H18V20.25H6Z" 
      fill="currentColor" />
      <path d="M14.25 9.75C14.6642 9.75 15 10.0858 15 10.5V16.5C15 16.9142 14.6642 17.25 14.25 17.25C13.8358 17.25 13.5 16.9142 13.5 16.5V10.5C13.5 10.0858 13.8358 9.75 14.25 9.75Z" 
      fill="currentColor" />
    </svg>
  );
}

export default TrashIcon;