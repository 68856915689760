import { ISvg } from "../interfaces/ISvg";

function ExcelIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.4848 1.42774C15.653 1.57024 15.75 1.77954 15.75 2.00001V22C15.75 22.2205 15.653 22.4298 15.4848 22.5723C15.3166 22.7148 15.0942 22.776 14.8767 22.7398L2.8767 20.7398C2.51506 20.6795 2.25 20.3666 2.25 20V4.00001C2.25 3.63338 2.51506 3.32048 2.8767 3.26021L14.8767 1.26021C15.0942 1.22397 15.3166 1.28524 15.4848 1.42774ZM3.75 4.63535V19.3647L14.25 21.1147V2.88535L3.75 4.63535ZM17.25 4.00001C17.25 3.58579 17.5858 3.25001 18 3.25001H21C21.4142 3.25001 21.75 3.58579 21.75 4.00001V20C21.75 20.4142 21.4142 20.75 21 20.75H18C17.5858 20.75 17.25 20.4142 17.25 20C17.25 19.5858 17.5858 19.25 18 19.25H20.25V4.75001H18C17.5858 4.75001 17.25 4.41422 17.25 4.00001ZM6.06407 7.88971C6.40113 7.64895 6.86954 7.72702 7.1103 8.06408L9 10.7097L10.8897 8.06408C11.1305 7.72702 11.5989 7.64895 11.9359 7.88971C12.273 8.13046 12.3511 8.59888 12.1103 8.93594L9.92168 12L12.1103 15.0641C12.3511 15.4011 12.273 15.8696 11.9359 16.1103C11.5989 16.3511 11.1305 16.273 10.8897 15.9359L9 13.2904L7.1103 15.9359C6.86954 16.273 6.40113 16.3511 6.06407 16.1103C5.72701 15.8696 5.64894 15.4011 5.8897 15.0641L8.07832 12L5.8897 8.93594C5.64894 8.59888 5.72701 8.13046 6.06407 7.88971Z" 
      fill="currentColor" />
    </svg>
  );
}

export default ExcelIcon;