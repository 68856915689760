import { ISvg } from "../interfaces/ISvg";

function LogOutIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.25 4C16.25 3.58579 16.5858 3.25 17 3.25H20C20.4641 3.25 20.9092 3.43437 21.2374 3.76256C21.5656 4.09075 21.75 4.53587 21.75 5V19C21.75 19.4641 21.5656 19.9092 21.2374 20.2374C20.9092 20.5656 20.4641 20.75 20 20.75H17C16.5858 20.75 16.25 20.4142 16.25 20C16.25 19.5858 16.5858 19.25 17 19.25H20C20.0663 19.25 20.1299 19.2237 20.1768 19.1768C20.2237 19.1299 20.25 19.0663 20.25 19V5C20.25 4.93369 20.2237 4.87011 20.1768 4.82322C20.1299 4.77634 20.0663 4.75 20 4.75H17C16.5858 4.75 16.25 4.41421 16.25 4ZM13.4697 8.46967C13.7626 8.17678 14.2374 8.17678 14.5303 8.46967L17.5303 11.4697C17.8232 11.7626 17.8232 12.2374 17.5303 12.5303L14.5303 15.5303C14.2374 15.8232 13.7626 15.8232 13.4697 15.5303C13.1768 15.2374 13.1768 14.7626 13.4697 14.4697L15.1893 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H15.1893L13.4697 9.53033C13.1768 9.23744 13.1768 8.76256 13.4697 8.46967Z" 
      fill="currentColor" />
    </svg>
  );
}

export default LogOutIcon;