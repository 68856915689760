import { useEffect, useState } from "react"
import Paper from "../layout/Paper"
import TextBox from "../UI/inputs/textInput/TextBox"
import PasswordBox from "../UI/inputs/textInput/PasswordBox"
import Button from "../UI/buttons/Button"
import { ResponseContainer } from "../../API/BaseApi"
import { Login, } from "../../API/base/authRegApi/authRegFunctions"
import { AdminLogin } from "../../API/admin/adminAuthApi/AdminAuthFunctions"




interface IProps {
    onAuth(): void;
}

const AuthAdminForm = (props: IProps) => {
    const [password, setPassword] = useState<string>("");
    const [login, setLogin] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setError(false)
    }, [password, login])

    const authHandler = () => {
        AdminLogin({username: login, password: password }).then((r: ResponseContainer<null>) => {
            onAuth(r);
        });
    };

    const onAuth = (resp: ResponseContainer<null>) => {
        if (resp.status === "error") {
            setError(true);
        } else {
            props.onAuth();
        }
    };

    return <Paper className="flex w-full flex-col gap-8 px-10 pb-10 pt-8">
        <div className="flex w-full flex-col gap-8">
            <div className="flex w-full flex-col gap-6">
                <TextBox
                    error={error}
                    defaultValue={login}
                    required
                    label="Логин"
                    onChange={(ev) => {
                        setLogin(ev.target.value);
                    }}
                    onKeyUp={(ev) => {
                        if (ev.key === "Enter") authHandler();
                    }}
                />
                <PasswordBox
                    description="Неверный логин или пароль"
                    error={error}
                    label="Пароль"
                    required
                    onChange={(ev) => {
                        setPassword(ev.target.value);
                        setError(false);
                    }}
                    onKeyUp={(ev) => {
                        if (ev.key === "Enter") authHandler();
                    }}
                />
            </div>
            <Button
                disabled={error}
                noFish
                onClick={() => {
                    authHandler();
                }}
            >
                Войти
            </Button>
        </div>
    </Paper>
}

export default AuthAdminForm