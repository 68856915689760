import { ISvg } from "../interfaces/ISvg";

function RefreshIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.00195 2.3623C4.41617 2.3623 4.75195 2.69809 4.75195 3.1123V7.99911C5.41041 7.02373 6.26325 6.1848 7.26535 5.53931C8.89294 4.49092 10.8223 4.01206 12.7512 4.17774C14.6801 4.34342 16.4996 5.14428 17.9246 6.45486C19.3495 7.76544 20.2995 9.51169 20.6256 11.42C20.9518 13.3284 20.6357 15.291 19.7268 17.0004C18.818 18.7099 17.3677 20.0695 15.6033 20.8664C13.8389 21.6633 11.86 21.8523 9.97663 21.404C8.09325 20.9556 6.4118 19.8952 5.19571 18.3887C4.93553 18.0664 4.98588 17.5943 5.30818 17.3341C5.63048 17.0739 6.10268 17.1242 6.36286 17.4465C7.37043 18.6947 8.76357 19.5733 10.324 19.9448C11.8845 20.3162 13.5241 20.1596 14.9859 19.4994C16.4478 18.8391 17.6494 17.7126 18.4024 16.2963C19.1554 14.88 19.4173 13.2539 19.1471 11.6727C18.8769 10.0916 18.0898 8.64477 16.9092 7.55891C15.7285 6.47305 14.221 5.80951 12.6229 5.67224C11.0247 5.53497 9.42614 5.93172 8.07763 6.80034C7.03761 7.47026 6.19164 8.39165 5.61318 9.47171H11.1114C11.5256 9.47171 11.8614 9.80749 11.8614 10.2217C11.8614 10.6359 11.5256 10.9717 11.1114 10.9717H4.48185C4.47001 10.972 4.45813 10.972 4.44623 10.9717H4.00195C3.58774 10.9717 3.25195 10.6359 3.25195 10.2217V3.1123C3.25195 2.69809 3.58774 2.3623 4.00195 2.3623Z" 
      fill="currentColor" />
    </svg>
  );
}

export default RefreshIcon;