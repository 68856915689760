import { ISvg } from "../interfaces/ISvg";

function LockIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.64124 3.64124C9.53204 2.75044 10.7402 2.25 12 2.25C13.2598 2.25 14.468 2.75044 15.3588 3.64124C16.2496 4.53204 16.75 5.74022 16.75 7V8.25H18C18.9665 8.25 19.75 9.0335 19.75 10V20C19.75 20.9665 18.9665 21.75 18 21.75H6C5.0335 21.75 4.25 20.9665 4.25 20V10C4.25 9.0335 5.0335 8.25 6 8.25H7.25V7C7.25 5.74022 7.75044 4.53204 8.64124 3.64124ZM6 9.75C5.86193 9.75 5.75 9.86193 5.75 10V20C5.75 20.1381 5.86193 20.25 6 20.25H18C18.1381 20.25 18.25 20.1381 18.25 20V10C18.25 9.86193 18.1381 9.75 18 9.75H6ZM15.25 8.25H8.75V7C8.75 6.13805 9.09241 5.3114 9.7019 4.7019C10.3114 4.09241 11.138 3.75 12 3.75C12.862 3.75 13.6886 4.09241 14.2981 4.7019C14.9076 5.3114 15.25 6.13805 15.25 7V8.25Z" 
      fill="currentColor" />
    </svg>
  );
}

export default LockIcon;