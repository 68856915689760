import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { ChangeProfileRequest, getProfileResponse } from "../../../../../API/base/profileApi/profileInterfaces";
import LinkButton from "../../../../UI/buttons/LinkButton";
import PencilIcon from "../../../../../assets/icons/PencilIcon";
import TextBoxDefault from "../../../../UI/inputs/textInput/TextBoxDefault";
import Button from "../../../../UI/buttons/Button";
import { Verification } from "../../../../../API/base/authRegApi/authRegFunctions";
import ActionModal from "../../../../modals/ActionModal";
import { checkEmail } from "../../../../../utils/validators";
import { toast } from "react-toastify";


interface IProps {
  userEmail: string | undefined,
}

const ProfileEmailField: FC<IProps> = ({
  userEmail,
}) => {
  useEffect(() => {
    if (userEmail) {
      setTempEmail(userEmail);
    }
  }, [userEmail]);

  const [emailIsEdit, setEmailIsEdit] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false)
  const [tempEmail, setTempEmail] = useState<string>(
    userEmail ? userEmail : ""
  );

  const onCheckEmail = () => {
    const isValidEmail = validateEmail(tempEmail);
    if (!isValidEmail) {
      toast.error("Email некорректен");
      return;
    }
    Verification(tempEmail).then((res) => {
      if (res.status == "error") {
        toast.error("Ошибка! " + res.message)
        return
      }
      setIsOpenEmailConfirmAlertModal(true)
    })
    setEmailIsEdit(false);
  };
  const [isOpenEmailConfirmAlertModal, setIsOpenEmailConfirmAlertModal] = useState<boolean>(false)
  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onSetEmail = (email: string) => {
    if (
      checkEmail(tempEmail) ||
      email.length === 0
    ) {
      setEmailError(false);
      setTempEmail(email);
    } else {
      setEmailError(true);
    }
  }

  return (
    <>
      {!userEmail ? (
        <></>
      ) : (
        <span className="flex flex-col gap-2 mt-8">
          {!emailIsEdit ? (
            <>
              <h1 className="text-[13px] text-gray-4 font-normal">E-mail</h1>
              <span className="flex gap-6 items-center">
                {userEmail}
                <LinkButton onClick={() => setEmailIsEdit(true)} noFish>
                  <PencilIcon size={20} />
                  Изменить e-mail
                </LinkButton>
              </span>
            </>
          ) : (
            <div className="">
              <div className="flex items-center">
                <TextBoxDefault
                  onChange={(e) => onSetEmail(e.target.value)}
                  value={tempEmail}
                  label="Изменить e-mail «qwertyui@mail.ru»"
                  placeholder="Новый e-mail"
                  className="!w-[424px]"
                />
                <div className="ml-6 mt-4 text-gray-4 text-xs font-normal">
                  На новую почту будет отправлено письмо с ссылкой для подтверждения.
                </div>
              </div>

              <div>
                <div className="py-4 gap-4 flex items-center">
                  <Button
                    onClick={onCheckEmail}
                    className="w-fit"
                    rounded="small"
                    noFish
                    size="small"
                  >
                    Получить ссылку
                  </Button>
                  <Button
                    onClick={() => setEmailIsEdit(false)}
                    className="w-fit"
                    variant="secondary"
                    rounded="small"
                    noFish
                    size="small"
                  >
                    Отмена
                  </Button>
                </div>
              </div>
            </div>
          )}
        </span>
      )}
      <ActionModal
        toggleOpen={setIsOpenEmailConfirmAlertModal}
        isOpen={isOpenEmailConfirmAlertModal}
        headerText="Подтвердите ваш e-mail"
        text={`На почтовый ящик ${tempEmail} было отправлено письмо с ссылкой для подтверждения. Пожалуйста, перейдите по ссылке, чтобы сохранить новую почту`}
        onActionText="Принять"
        onAction={() => { setIsOpenEmailConfirmAlertModal(false) }}
      />
    </>
  );
};

export default ProfileEmailField;
