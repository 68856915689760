import ModalBase, { ModalPropsNoChildren } from "../modals/ModalBase";
import Loader from "./loader";

const LoaderModal = (props: ModalPropsNoChildren) => {
	return (
		<ModalBase className="shadow-1" open={props.open} onOpenChange={props.onOpenChange as () => void}>
			<Loader />
		</ModalBase>
	);
};

export default LoaderModal; 