import * as DropdownMenuBase from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import {forwardRef} from "react";

import DropdownBase, {DropdownMenuProps} from "./DropdownBase";

const DropdownMenu = forwardRef<HTMLButtonElement, DropdownMenuProps>(
	(props: DropdownMenuProps, ref) => {
		return (
			<DropdownBase
				ref={ref}
				align="end"
				styleClasses={{
					content: "gap-1 py-1", arrow:""
				}}
				trigger={props.children}
				onOpenChange={(value) => props.onOpenChange?.(value)}
			>
				{props.items.map((item, ind) => (
					<DropdownMenuBase.Item
						key={`ddm-${ind}`}
						className={clsx(
							"flex h-8 cursor-pointer select-none items-center px-3 outline-none hover:bg-gray-1",
							"data-[disabled]:pointer-events-none",
							props.styleClasses?.item,
						)}
					>
						{item}
					</DropdownMenuBase.Item>
				))}
			</DropdownBase>
		);
	},
);

DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
