import { FC, useEffect, useState } from "react";
import SearchBox from "../../../UI/inputs/textInput/SearchBox";
import Button from "../../../UI/buttons/Button";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import ScrollArea from "../../../UI/wrappers/ScrollArea";
import IconButton from "../../../UI/buttons/IconButton";
import ConfirmModal from "../../../modals/ConfirmModal";
import { scrollListTop } from "../../../../utils/reusedFunks";
import CreateOrEditAdminModal from "../../../modals/CreateOrEditAdminModal";
import { CreateAdminRequest, GetAdminsRequest, IAdminUser } from "../../../../API/admin/adminsApi/adminsInterfaces";
import { CreateAdmin, DeleteAdmin, EditAdmin, GetAdmins } from "../../../../API/admin/adminsApi/adminsFunctions";
import { GetPayload } from "../../../../API/base/payloadApi/payloadFunctions";
import { IPartner, IPartnerAdmin } from "../../../../assets/interfaces/IPartner";
import { GetPartnerData } from "../../../../API/admin/partnersApi/partnersFunctions";
import EditPartnerWindow from "./AdminPartners/components/EditPartnerWindow";
import { toast } from "react-toastify";
import Loader from "../../../UI/loader/loader";
import LoaderModal from "../../../UI/loader/LoaderModal";

interface IProps {

}
const Admins: FC<IProps> = ({ }) => {
  const usersPageSize = 20;
  const [admins, setAdmins] = useState<IAdminUser[]>([]);
  const [editAdmin, setEditAdmin] = useState<IAdminUser>();
  const [deleteAdmin, setDeleteAdmin] = useState<IAdminUser>();
  const [createAdminModal, setCreateAdminModal] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>();
  const [page, setPage] = useState<number>(0);
  const [totalAdminsCount, setTotalAdminsCount] = useState(0);
  const [parnterCardData, setPartnerCardData] = useState<IPartnerAdmin>()
  const [partners, setPartners] = useState<IPartner[]>([])

  const [getAdminsLoading, setGetAdminsLoading] = useState<boolean>(false)
  const [adminEventLoading, setAdminEventLoading] = useState<boolean>(false)

  useEffect(() => {
    onGetPartners()
  }, [])

  useEffect(() => {
    if (page > 0) {
      onGetAdmins(page, searchText)
    }
  }, [page])

  const onGetPartners = () => {
    GetPayload({ partners: true }).then((r) => {
      if (r.status !== "error") {
        setPartners(r.data?.partners as IPartner[])
      }
    })
  }

  const onGetAdmins = (page: number, searchText: string | undefined) => {
    let reqParams: GetAdminsRequest = { pagination: { page: page, pageSize: usersPageSize } }
    if (searchText) {
      reqParams = { ...reqParams, filterBy: { searchText: searchText } }
    }
    if (page === 0) setGetAdminsLoading(true)
    GetAdmins(reqParams).then((r) => {
      if (r.status !== "error" && r.data) {
        if (page === 0) {
          scrollListTop("mainList")
          setAdmins(r.data.admins)
          setPage(0)
        }
        else {
          setAdmins([...admins, ...r.data.admins])
        }
        setTotalAdminsCount(r.data.totalCount)
      }
      setGetAdminsLoading(false)
    })
  }

  const onEditAdmin = (newUserData: IAdminUser) => {
    setAdminEventLoading(true)
    EditAdmin(newUserData.id as string, { firstName: newUserData.firstName, lastName: newUserData.lastName, login: newUserData.login, role: newUserData.role, partners: newUserData.partners }).then((r) => {
      if (r.status !== "error") {
        toast.success("Изменения сохранены")
        onGetAdmins(0, searchText)
        setEditAdmin(undefined)
      }
      else {
        toast.error("Ошибка сохранения")
      }
      setAdminEventLoading(false)
    })

  }
  const onCreateAdmin = (adminData: CreateAdminRequest) => {
    setAdminEventLoading(true)
    CreateAdmin({ ...adminData }).then((r) => {
      if (r.status !== "error") {
        toast.success(`Добавлен администратор ${adminData.firstName} ${adminData.lastName}`)
        onGetAdmins(0, searchText)
        setCreateAdminModal(false)
      }
      else {
        toast.error("Не удалось создать администратора")
      }
      setAdminEventLoading(false)
    })

  }

  const onDeleteAdmin = () => {
    setAdminEventLoading(true)
    DeleteAdmin(deleteAdmin?.id as string).then((r) => {
      if (r.status !== "error") {
        toast.success(`Администратор ${deleteAdmin?.firstName} ${deleteAdmin?.lastName} удален`)
        onGetAdmins(0, searchText)
        setDeleteAdmin(undefined)
      }
      else {
        toast.error("Ошибка при удалении")
      }
      setAdminEventLoading(false)
    })

  }

  const onSrollEnd = () => {
    if (admins && totalAdminsCount > admins.length) {
      setPage(page + 1)
    }
  }

  const onGetPartnerData = (partnerId: string) => {
    GetPartnerData(partnerId).then((r) => {
      if (r.status !== "error") {
        setPartnerCardData(r.data)
      }
    })
  }

  const adminsList = admins.map((u: IAdminUser) => {
    const adminPartners = partners.filter((p) => {
      return u.partners.findIndex((AddedPartnerId: string) => AddedPartnerId === p.id) >= 0
    })

    const adminPartnersList = adminPartners.map((p, i) => {
      return <span onClick={() => onGetPartnerData(p.id)} key={p.id} className="text-accent cursor-pointer hover:underline hover:text-accent-dark">
        {`${p.name}`}{i !== adminPartners.length - 1 && <span>,</span>}
      </span>
    })

    return <div key={u.id} className="w-full flex min-h-[60px] items-center px-[60px] border-b border-gray-2">
      <p className="flex-1">{`${u.firstName} ${u.lastName}`}</p>
      <div className="text-left flex-1 flex gap-1 overflow-hidden flex-wrap pr-4">{adminPartnersList}</div>
      <div className="w-1/6 justify-start flex items-center gap-1">
        <p className="text-left w-full">{u.login}</p>
        <IconButton onClick={() => setEditAdmin(u)}><PencilIcon size={16} /></IconButton>
        <IconButton onClick={() => setDeleteAdmin(u)}><TrashIcon size={16} /></IconButton>
      </div>
    </div>
  })

  return (
    <div className="w-full h-full">
      {
        !parnterCardData ? <div className="flex flex-col w-full gap-8 h-full pt-4">
          <div className="flex items-center w-full px-10">
            <div className="flex-1">
              <div className="w-[592px]">
                <SearchBox onClear={() => onGetAdmins(0, undefined)}
                  onSearchDebounced={(searchText: string) => onGetAdmins(0, searchText)}
                  onChangeText={setSearchText} placeholder="Поиск" />
              </div>
            </div>
            <Button onClick={() => setCreateAdminModal(true)} noFish className="w-auto rounded-lg"><PlusIcon size={16} /> Новый администратор</Button>
          </div>
          <div className="flex-1 w-full flex flex-col overflow-hidden">
            <div className="w-full h-10 flex items-center bg-gray-1 px-[60px] text-gray-4 border-b border-gray-2">
              <span className="flex-1">Администраторы</span>
              <span className="flex-1">Партнеры</span>
              <span className="text-left w-1/6">Логин</span>
            </div>
            {!getAdminsLoading ? <ScrollArea onScrollEnd={onSrollEnd} id="mainList">
              {adminsList}
            </ScrollArea> : <Loader />}
          </div>
          {typeof (editAdmin) === "object" && <CreateOrEditAdminModal
            partners={partners}
            adminData={editAdmin}
            renderFor="edit"
            editFunk={onEditAdmin}
            isOpen={typeof (editAdmin) === "object"}
            toggleOpen={() => setEditAdmin(undefined)} />}
          {createAdminModal && <CreateOrEditAdminModal
            partners={partners}
            renderFor="create"
            createFunk={onCreateAdmin}
            isOpen={createAdminModal}
            toggleOpen={() => setCreateAdminModal(false)} />}
          <ConfirmModal
            isDelete
            funk={onDeleteAdmin}
            toggleOpen={() => setDeleteAdmin(undefined)}
            headText="Удалить администратора?"
            text={`Вы действительно хотите удалить администратора ${deleteAdmin?.firstName} ${deleteAdmin?.lastName}?`}
            confirmBtnText="Удалить"
            isOpen={typeof (deleteAdmin) === "object"} />
        </div> :
          <EditPartnerWindow backFunk={() => setPartnerCardData(undefined)} partnerData={parnterCardData} read={true} />
      }
      <LoaderModal open={adminEventLoading} />
    </div>
  );
}

export default Admins;