import clsx from "clsx";
import React from "react";

interface Props {
	children: React.ReactNode;
	className?: string;
}

const Paper = (props: Props) => {
	return (
		<div
			className={clsx("rounded-2xl bg-white px-10 py-8 outline-none", props.className)}
		>
			{props.children}
		</div>
	);
};

export default Paper;
