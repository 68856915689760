import { FC } from "react";

import IconButton from "../buttons/IconButton";
import ModalBase from "./ModalBase";
import CrossIcon from "../../../assets/icons/CrossIcon";


interface IProps {
    isOpen: boolean;
    toggleOpen:() => void;
    headText: string;
    text: string;
}
 
const ErrorModal: FC<IProps> = ({isOpen, toggleOpen, headText, text}) => {
    return (<ModalBase className="rounded-lg" onOpenChange={toggleOpen} open = {isOpen}>
        <div className="w-[360px] flex flex-col items-center">
            <span className="w-full flex items-start justify-end -mt-6 -mr-16">
                <IconButton onClick={toggleOpen} size={"small"}><CrossIcon size={16}/></IconButton>
            </span>
            <div className="w-full flex flex-col items-center gap-2">
                <span className="flex items-center justify-center w-16 h-16 rounded-[50%] bg-system-red text-white"><CrossIcon size={24}/></span>
                <p className="text-lg font-semibold text-center">{headText}</p>
                <p className="text-xs text-gray-3 font-normal text-center">{text}</p>
            </div>
        </div>
    </ModalBase>);
}
 
export default ErrorModal;