import { ISvg } from "../interfaces/ISvg";

function ShablonIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V16.1893L14.4697 14.4697C14.7626 14.1768 15.2374 14.1768 15.5303 14.4697C15.8232 14.7626 15.8232 15.2374 15.5303 15.5303L12 19.0607L8.46967 15.5303C8.17678 15.2374 8.17678 14.7626 8.46967 14.4697C8.76256 14.1768 9.23744 14.1768 9.53033 14.4697L11.25 16.1893V10C11.25 9.58579 11.5858 9.25 12 9.25Z" 
      fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5 1.25C4.0335 1.25 3.25 2.0335 3.25 3V21C3.25 21.9665 4.03351 22.75 5 22.75H19C19.9665 22.75 20.75 21.9665 20.75 21V6.68934L15.3107 1.25H5ZM5 2.75C4.86193 2.75 4.75 2.86193 4.75 3V21C4.75 21.1381 4.86192 21.25 5 21.25H19C19.1381 21.25 19.25 21.1381 19.25 21V7.75H14.25V2.75H5ZM15.75 3.81066L18.1893 6.25H15.75V3.81066Z" 
      fill="currentColor" />
    </svg>
  );
}

export default ShablonIcon;