import { FC, useState } from "react";
import ModalBase from "../../../../../UI/modals/ModalBase";
import IconButton from "../../../../../UI/buttons/IconButton";
import CrossIcon from "../../../../../../assets/icons/CrossIcon";
import PasswordBox from "../../../../../UI/inputs/textInput/PasswordBox";
import Button from "../../../../../UI/buttons/Button";
import { ChangePasswordData } from "../../interfaces/Profile";
import { ChangePassword } from "../../../../../../API/base/profileApi/profileFunctions";
import { ChangePasswordRequest } from "../../../../../../API/base/profileApi/profileInterfaces";
import { toast } from "react-toastify";

interface IProps {
  open?: boolean;
  onOpenChange: (open: boolean) => void;
}

const ModalProfilePasswordChangeModal: FC<IProps> = ({
  onOpenChange,
  open,
}) => {

  const [changePasswordData, setChangePasswordData] = useState<ChangePasswordData>({
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: ''
  })

  const checkPassword = () => {
    if (changePasswordData.newPassword !== changePasswordData.repeatNewPassword) {
      toast.error("Пароли не совпадают")
      return
    }
    const password: ChangePasswordRequest = {
      currentPassword: changePasswordData.currentPassword,
      newPassword: changePasswordData.newPassword
    }
    ChangePassword(password).then((response: any)=>{
      if (response.status!=="error") {
        toast.success("Пароль изменен")
        onOpenChange(false)
      }
      else {
        toast.error(response.message)
      }
    })
  }
  
  return (
    <ModalBase open={open} onOpenChange={onOpenChange}> <div className="max-w-[445px] min-w-[400px] flex flex-col">
      <div className="w-full flex justify-end">
        <IconButton onClick={() => onOpenChange(false)} className="-translate-y-4 translate-x-4" size="small">
          <CrossIcon />
        </IconButton>
      </div>
      {<div className="flex flex-col gap-4">
        <h1 className="text-2xl font-semibold">Смена пароля</h1>
        <PasswordBox
          description={"Для ошибки"}
          label="Текущий пароль"
          required
          onChange={(e) => { setChangePasswordData({ ...changePasswordData, currentPassword: e.target.value }) }}
        />
        <div className="w-full">
          <PasswordBox
            description="Пароль содержит недопустимы символы"
            label="Новый пароль"
            required
            onChange={(e) => { setChangePasswordData({ ...changePasswordData, newPassword: e.target.value }) }}
          />
        </div>
        <PasswordBox
          description="Пароли не совпадают"
          label="Повторите новый пароль"
          required
          onChange={(e) => { setChangePasswordData({ ...changePasswordData, repeatNewPassword: e.target.value }) }}
        />
        <Button
          disabled={(changePasswordData.currentPassword &&
            changePasswordData.newPassword &&
            changePasswordData.repeatNewPassword)
            ? 
            false : true}
          onClick={checkPassword}
          variant={"primary"}
          className={`w-auto rounded-lg`}
          noFish>
          Изменить пароль
        </Button>
      </div>}
    </div>
    </ModalBase>
  );
}

export default ModalProfilePasswordChangeModal;