import { FC, useState } from "react";
import {
  IPartnerAdmin,
  PartnerCategory,
} from "../../../../../../assets/interfaces/IPartner";
import Button from "../../../../../UI/buttons/Button";
import DownloadIcon from "../../../../../../assets/icons/DownloadIcon";
import Select from "../../../../../UI/inputs/dropdown/Select";
import TextBox from "../../../../../UI/inputs/textInput/TextBox";
import ScrollArea from "../../../../../UI/wrappers/ScrollArea";
import { EditPartnerRequest } from "../../../../../../API/admin/partnersApi/partnersInterfaces";
import {
  EditPartner,
  GetPartnerToken,
  LoadPartnerLogo,
} from "../../../../../../API/admin/partnersApi/partnersFunctions";
import ErrorModal from "../../../../../UI/modals/ErrorModal";
import ArrowLeftIcon from "../../../../../../assets/icons/ArrowLeftIcon";
import PartnerTokenModal from "../../../../../modals/PartnerTokenModal";
import { toast } from "react-toastify";
import ActionModal from "../../../../../modals/ActionModal";
import systemeIcon from "../../../../../../assets/systemeIcon.svg";
import CopyIcon from "../../../../../../assets/icons/CopyIcon";
import IconButton from "../../../../../UI/buttons/IconButton";
import {
  onGeneratePassword,
  onTextCopy,
} from "../../../../basicPages/utils/utils";
import PencilIcon from "../../../../../../assets/icons/PencilIcon";
import Tooltip from "../../../../../UI/Tooltip/Tooltip";
import LoaderModal from "../../../../../UI/loader/LoaderModal";
import PasswordBox from "../../../../../UI/inputs/textInput/PasswordBox";
import ConfirmModal from "../../../../../modals/ConfirmModal";
import Checkbox from "../../../../../UI/inputs/checkBox/CheckBox";
import { AdminRole } from "../../../../../../API/admin/adminAuthApi/AdminAuthInterfaces";
import InfoIcon from "../../../../../../assets/icons/InfoIcon";
import { PARTNER_CATEGORIES } from "../../../../../../assets/enums/Partner";
import { checkLogin } from "../../../../../../utils/validators";

interface IProps {
  partnerData: IPartnerAdmin;
  onEditPartner?: (
    partnerId: string,
    newPartnerData: EditPartnerRequest
  ) => void;
  read?: boolean;
  backFunk?: () => void;
  userRole: AdminRole;
  deletePartnerFunk?: (uuid: string) => void;
}

const MAX_FILE_SIZE = 8388608;

const EditPartnerWindow: FC<IProps> = ({
  partnerData,
  userRole,
  onEditPartner,
  read,
  backFunk,
  deletePartnerFunk,
}) => {
  const [selectedCaregory, setSelectedCategory] = useState<PartnerCategory>(
    partnerData.category
  );
  const [partnerName, setPartnerName] = useState<string>(partnerData.name);
  const [partnerlogin, setPartnerLogin] = useState<string>(
    partnerData.login as string
  );
  const [partnerPassword, setPartnerPassword] = useState<string>();
  const [partnerB2bLink, setPartnerB2bLink] = useState<string>(
    partnerData.b2bLink as string
  );
  const [partnerB2cLink, setPartnerB2cLink] = useState<string>(
    partnerData.b2cLink as string
  );
  const [partnerAvatar, setPartnerAvatar] = useState<string>(
    partnerData.avatar as string
  );
  const [failLoad, setFailLoad] = useState<boolean>(false);
  const [isOpenTokenModal, setIsOpenTokenModal] = useState<boolean>(false);
  const [isConfirmGenerateTokenModal, setIsConfirmGenerateTokenModal] =
    useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveLoding, setSaveLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [partnerIsVisible, setPartnerIsVisible] = useState<boolean>(
    partnerData.isVisible
  );

  const isForEschange =
    selectedCaregory === "exchange" || selectedCaregory === "all";
  const isForBasket =
    selectedCaregory === "basket" || selectedCaregory === "all";

  const combineChangedPartnerData = () => {
    const newData = {} as EditPartnerRequest;
    if (selectedCaregory !== partnerData.category)
      newData.category = selectedCaregory;
    if (partnerName !== partnerData.name) newData.name = partnerName;
    if (partnerlogin !== partnerData.login) newData.login = partnerlogin;
    if (partnerPassword) newData.password = partnerPassword;
    if (partnerB2bLink !== partnerData.b2bLink)
      newData.b2bLink = partnerB2bLink;
    if (selectedCaregory === "basket" && partnerB2cLink !== partnerData.b2cLink)
      newData.b2cLink = partnerB2cLink;
    if (
      (userRole === "admin" || userRole === "partner") &&
      partnerIsVisible !== partnerData.isVisible
    )
      newData.isVisible = partnerIsVisible;
    return newData;
  };

  const isValidForSave =
    selectedCaregory &&
    partnerName &&
    partnerlogin &&
    partnerB2bLink &&
    Object.keys(combineChangedPartnerData()).length > 0 &&
    ((isForEschange && partnerB2bLink) || !isForEschange) &&
    ((isForBasket && partnerB2cLink) || !isForBasket);

  const onSaveChanges = () => {
    onEditPartner && onEditPartner(partnerData.id, combineChangedPartnerData());
  };

  const onPartnerEditSave = () => {
    setSaveLoading(true);
    EditPartner(partnerData.id, combineChangedPartnerData()).then((r) => {
      if (r.status !== "error") {
        toast.success("Изменения сохранены");
      } else {
        toast.error(r.message || "Ошибка при сохранении");
      }
      setSaveLoading(false);
    });
  };

  const onLoadFile = (file: any) => {
    const fileGroupType: string = file.type.split("/")[0];
    const fileType: string = file.type.split("/")[1];
    if (fileGroupType === "image") {
      if (["jpeg", "jpg", "png", "svg"].includes(fileType.toLowerCase())) {
        if (file.size <= MAX_FILE_SIZE) {
          LoadPartnerLogo(partnerData.id, file).then((r) => {
            if (r.status !== "error") {
              setPartnerAvatar(r.data?.avatar as string);
            } else {
              setFailLoad(true);
            }
          });
          return;
        }
      }
    }
    setFailLoad(true);
  }

    const onConfirmGenerateNewToken = () => {
      setIsConfirmGenerateTokenModal(false);
      onGetPartnerToken();
    };

    const onGetPartnerToken = () => {
      GetPartnerToken(partnerData.id).then((r) => {
        setIsLoading(true);
        setIsOpenTokenModal(true);
        if (r.data?.token) {
          setToken(r.data.token);
        } else {
          setIsOpenTokenModal(false);
          toast.error("Ошибка получения токена");
        }
        setIsLoading(false);
      });
    };

    const onPasswordChange = (text: string) => {
      if (text == "" || !text) {
        setPartnerPassword(undefined);
        return;
      }
      setPartnerPassword(text);
    };

    const onSetLogin = (str: string) => {
      if (!checkLogin(str) && str !== "") return
      setPartnerLogin(str)
    }

    return (
      <ScrollArea className="w-full flex flex-col items-center px-4">
        <div className="w-full flex flex-col items-center">
          <div className="flex content-center relative">
            {userRole !== "partner" && (
              <span
                className="w-8 h-8 bg-gray-1 flex items-center justify-center rounded-lg text-accent cursor-pointer 
              hover:text-accent-dark hover:bg-gray-2 transition-all absolute -left-10"
                onClick={backFunk}
              >
                <ArrowLeftIcon size={16} />
              </span>
            )}
            <h1 className="text-2xl font-[550] mb-4">
              {!read ? "Редактировать карточку партнера" : "Карточка партнера"}
            </h1>
            {partnerData.isSuper && (
              <Tooltip text="Это системный партнер для прямой интеграции, любое вмешательство может навредить работе сайта">
                <span className="absolute -right-8 top-1">
                  <InfoIcon className="text-system-yellow" />
                </span>
              </Tooltip>
            )}
          </div>
          <img
            className="w-[100px] h-[100px] select-none mb-4 rounded-md"
            src={partnerAvatar || systemeIcon}
            alt="logo"
          />
          {!read && (
            <label
              htmlFor="imageFile"
              className="w-auto text-accent flex items-center cursor-pointer gap-1 hover:text-accent-dark select-none transition-all"
            >
              <DownloadIcon size={16} /> Загрузить лого
            </label>
          )}
          <div className="w-[420px] flex flex-col gap-5 mt-2">
            {userRole !== "partner" && (
              <Select
                value={selectedCaregory}
                onValueChange={(value: PartnerCategory) =>
                  !read && setSelectedCategory(value)
                }
                placeholder="Категория партнера"
                getItemTitle={(item) => item.name}
                getItemValue={(item) => item.value}
                items={PARTNER_CATEGORIES}
              />
            )}
            <TextBox
              value={partnerName}
              onChangeText={(text: string) => !read && setPartnerName(text)}
              label="Имя партнера"
            />
            <TextBox
              value={partnerlogin}
              onChangeText={(text: string) =>
                !read && userRole !== "partner" && onSetLogin(text)
              }
              label="Логин партнера"
            />
            {isForEschange && (
              <TextBox
                value={partnerB2bLink || ""}
                onChangeText={(text: string) =>
                  !read && setPartnerB2bLink(text)
                }
                label="Точка доступа b2b"
              />
            )}

            {isForBasket && (
              <TextBox
                value={partnerB2cLink || ""}
                onChangeText={(text: string) =>
                  !read && setPartnerB2cLink(text)
                }
                label="Точка доступа b2c"
              />
            )}
            {!read && (
              <div className="flex items-center justify-between gap-2">
                <div className="w-full">
                  <PasswordBox
                    value={partnerPassword}
                    onChange={(e) => {
                      !read && onPasswordChange(e.currentTarget.value);
                    }}
                    label="Пароль партнера"
                  />
                </div>
                <div className="flex gap-2">
                  <Tooltip text="Сгенерировать новый пароль">
                    <IconButton
                      size="big"
                      className=" !text-lg"
                      variant="primary"
                      onClick={() => setPartnerPassword(onGeneratePassword())}
                    >
                      <PencilIcon size={18} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip text="Скопировать пароль">
                    <IconButton
                      size="big"
                      className=" !text-lg"
                      variant="primary"
                      onClick={() =>
                        partnerPassword
                          ? onTextCopy(partnerPassword, "Пароль")
                          : toast.error("Нечего копировать")
                      }
                    >
                      <CopyIcon size={18} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
            <span className="m-auto">
              <Checkbox
                disabled={userRole !== "admin" && userRole !== "partner"}
                checked={partnerIsVisible}
                onCheckedChange={(checked: boolean) => {
                  setPartnerIsVisible(checked);
                }}
                label="Присутствует в выдаче"
              />
            </span>
            {!read && (
              <Button
                disabled={!isValidForSave}
                onClick={
                  userRole !== "partner" ? onSaveChanges : onPartnerEditSave
                }
                noFish
              >
                Сохранить
              </Button>
            )}
            <Button
              variant="tetriary"
              onClick={() => setIsConfirmGenerateTokenModal(true)}
              noFish
            >
              Получить токен
            </Button>
            {deletePartnerFunk && (
              <Button
                className="w-fit m-auto"
                size="small"
                variant="warning"
                onClick={() => setDeleteModalOpen(true)}
                noFish
              >
                Удалить партнера
              </Button>
            )}
          </div>
        </div>
        {!read && (
          <input
            className="hidden"
            onChange={(e: any) => {
              if (e.currentTarget.files[0]) {
                onLoadFile(e.currentTarget.files[0]);
              }
            }}
            id="imageFile"
            accept="image/png, image/jpeg, image/jpg, image/PNG, image/JPEG image/svg image/SVG"
            type="file"
          />
        )}
        <ActionModal
          isOpen={isConfirmGenerateTokenModal}
          toggleOpen={() => {
            setIsConfirmGenerateTokenModal(false);
          }}
          headerText="Подтверждение"
          text="При создании нового токена авторизация предыдущий перестанет работать. Вы уверены что хотите продолжить?"
          onAction={onConfirmGenerateNewToken}
          onActionText="Продолжить"
          onAbortText="Отмена"
        />
        <PartnerTokenModal
          isOpen={isOpenTokenModal}
          toggleOpen={() => {
            setIsOpenTokenModal(false);
          }}
          token={token}
          isLoading={isLoading}
        />
        <ErrorModal
          headText="Не удалось загрузить файл"
          isOpen={failLoad}
          text="Файл поврежден или имеет неверный формат"
          toggleOpen={() => setFailLoad(!failLoad)}
        />
        <LoaderModal open={saveLoding} />
        {deletePartnerFunk && (
          <ConfirmModal
            isOpen={deleteModalOpen}
            toggleOpen={() => setDeleteModalOpen(false)}
            funk={() => deletePartnerFunk(partnerData.id)}
            confirmBtnText="Удалить"
            isDelete
            headText="Удаление партнера"
            text={`Вы действительно хотите удалить партнера ${partnerData.name} ?`}
          />
        )}
      </ScrollArea>
    );
  };


export default EditPartnerWindow;
