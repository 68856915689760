import { FC } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";

interface IProps {
  open?: boolean;
  onOpenChange: (open: boolean) => void;
  isMobile: boolean;
}

const EmailErrorAuthModal: FC<IProps> = (props: IProps) => {
  return (
    <ModalBase open={props.open} onOpenChange={props.onOpenChange} fromBottom = {props.isMobile} className="h-screen sm:h-auto w-screen sm:w-auto shadow-1"> <div className="sm:w-[570px] flex flex-col">
      <div className="w-full flex justify-end">
        <IconButton onClick={() => { props.onOpenChange(false) }} className="-translate-y-4 translate-x-4" size="table">
          <CrossIcon />
        </IconButton>
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="font-semibold text-2xl pb-3">Возникли проблемы?</h1>
        <p>Мы видим, что у вас возникли трудности при подтверждении адреса электронной почты.</p>
        <p>Попробуйте поискать письмо в папке “Спам”, оно могло попасть туда по ошибке.</p>
        <p>Если вам нужна помощь, пожалуйста, свяжитесь с нашим Центром Поддержки Клиентов любым удобным способом:</p>
        <div className="font-semibold gap-4 flex flex-col pt-3">
          <p>8 800 200 64 46</p>
          <p>+7 495 777 99 88</p>
          <p>support@systeme.ru</p>
        </div>
      </div>
    </div>
    </ModalBase>
  );
}

export default EmailErrorAuthModal;