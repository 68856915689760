import clsx from "clsx";
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from "react";
import FishIcon from "../../../assets/icons/FishIcon";

type buttonType = "primary" | "secondary" | "tetriary" | "text" | "warning";
type buttonSize = "big" | "small"
type buttonRound = "big" | "small"

const typeClasses = {
  primary:
    "bg-accent text-white border-accent enabled:hover:bg-accent-dark  disabled:opacity-30 active:bg-accent-dark",
  secondary:
    "text-accent bg-gray-1 border-gray-1 enabled:hover:bg-gray-2 disabled:text-gray-3 active:bg-gray-2",
  tetriary:
    "text-accent bg-transparent border-gray-1 enabled:hover:border-gray-2 disabled:text-gray-2 active:border-gray-2",
  text: "text-accent border-transparent bg-transparent enabled:hover:text-accent-dark disabled:text-gray-2 active:text-accent-dark",
  warning:
    "bg-system-red text-white border-system-red enabled:hover:bg-system-red-hover disabled:bg-gray-2 disabled:border-gray-2 active:bg-system-red-hover",
};

const sizeClasses = {
  big: "px-6 h-12",
  small: "px-4 h-9",
};

const roundedClasses = {
  big: "rounded-[32px]",
  small: "rounded-[8px]",
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  rounded?: buttonRound;
  size?: buttonSize;
  variant?: buttonType;
  noFish?: boolean
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ size = "big", variant = "primary", rounded = "big", children, noFish, className, ...props }: Props, ref) => {
    return (
      <button
        ref={ref}
        className={clsx(
          "flex cursor-pointer w-[100%] items-center justify-center gap-1 whitespace-nowrap rounded-[32px] border text-center text-sm font-semibold",
          "overflow-ellipsis outline-none transition-all disabled:cursor-default select-none",
          sizeClasses[size],
          typeClasses[variant],
          roundedClasses[rounded],
          className,
        )}
        {...props}
      >
        {!noFish && <FishIcon size={20} />}
        {children}
        {!noFish && <FishIcon size={20} />}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;
