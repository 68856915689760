import { ISvg } from "../interfaces/ISvg";

function BuyIcon({ size = 24, className }: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 18 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.1305 1.13041C1.42743 0.833482 1.83016 0.666667 2.25008 0.666667L8.91675 0.666667C9.33096 0.666667 9.66675 1.00245 9.66675 1.41667C9.66675 1.83088 9.33096 2.16667 8.91675 2.16667L2.25008 2.16667C2.22798 2.16667 2.20678 2.17545 2.19116 2.19108C2.17553 2.2067 2.16675 2.2279 2.16675 2.25L2.16675 13.9167C2.16675 13.9388 2.17553 13.96 2.19116 13.9756C2.20678 13.9912 2.22798 14 2.25008 14L15.5834 14C15.6055 14 15.6267 13.9912 15.6423 13.9756C15.658 13.96 15.6667 13.9388 15.6667 13.9167V11.4167C15.6667 11.0025 16.0025 10.6667 16.4167 10.6667C16.831 10.6667 17.1667 11.0025 17.1667 11.4167V13.9167C17.1667 14.3366 16.9999 14.7393 16.703 15.0363C16.4061 15.3332 16.0033 15.5 15.5834 15.5L2.25008 15.5C1.83016 15.5 1.42743 15.3332 1.1305 15.0363C0.833561 14.7393 0.666748 14.3366 0.666748 13.9167L0.666748 2.25C0.666748 1.83008 0.833561 1.42735 1.1305 1.13041Z" fill="currentColor" />
      <path d="M13.3864 2.553C13.6793 2.26011 14.1542 2.26011 14.4471 2.553L16.9471 5.053C17.0877 5.19366 17.1667 5.38442 17.1667 5.58333C17.1667 5.78225 17.0877 5.97301 16.9471 6.11366L14.4471 8.61366C14.1542 8.90656 13.6793 8.90656 13.3864 8.61366C13.0935 8.32077 13.0935 7.8459 13.3864 7.553L14.6061 6.33333H12.8417C10.0982 6.33224 7.61322 7.53272 6.42098 9.91859C6.23582 10.2891 5.78535 10.4394 5.41483 10.2542C5.0443 10.0691 4.89403 9.61861 5.07918 9.24808C6.58658 6.23152 9.68071 4.83216 12.8417 4.83333H14.6061L13.3864 3.61366C13.0935 3.32077 13.0935 2.8459 13.3864 2.553Z" fill="currentColor" />
    </svg>
  );
}

export default BuyIcon;