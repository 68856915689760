import clsx from "clsx"
import { FC } from "react"
import SmallStep from "./SmallStep"

interface IProps {
    isMobile: boolean
}

const StepsSmall: FC<IProps> = (props: IProps) => {
	return (<div className={clsx(" flex items-center select-none flex-col h-[140px] sm:flex-row sm:h-auto")}>
		<SmallStep text="Выбор товаров" num={1} key={1} />
		<span className={clsx("flex-1 bg-accent", props.isMobile ? "w-[2px]" : "h-[2px]")} />
		<SmallStep text="Проверка наличия" num={2} key={2} />
		<span className={clsx("flex-1 bg-accent", props.isMobile ? "w-[2px]" : "h-[2px]")} />
		<SmallStep text="Выбор поставщиков" num={3} key={3} />
		<span className={clsx("flex-1 bg-accent", props.isMobile ? "w-[2px]" : "h-[2px]")} />
		<SmallStep text="Закупка" num={4} key={4} />
	</div>)
}

export default StepsSmall

