import { getCurrentUrl, P, requestFileGet, requestFilePost, requestGet, requestPost, requestPostForm } from "../../requester";
import {
    autoDistributeRequest, autoDistributeResponse,
    downloadSpecRequest, getDistributionInfoRequest,
    getDistributionInfoResponse, getReferencesRequest,
    getReferencesResponse, getScenariosRequest,
    getScenariosResponse, makeOrderRequest, makeOrderResponse,
    uploadSpecJsonResponse,
    uploadSpecResponse
} from "./exchangeInterfaces";

export function GetReferences(args: getReferencesRequest) {
    return requestPost<getReferencesResponse>(P.SearchRef, args)
}

export function UploadSpec(specFile: Blob) {
    const form = new FormData();
    form.append("file", specFile);
    return requestPostForm<uploadSpecResponse>(P.UploadSpec, form);
}

export function UploadSpecJson(specId: string) {
    const currentUrl = getCurrentUrl(P.UploadSpecJson, specId)
    return requestGet<uploadSpecJsonResponse>(currentUrl)
}


export function DownloadSpec(args: downloadSpecRequest, errorHandler?: (errorMsg: string) => void) {
    return requestFilePost(P.DownloadSpec, "Specification", "xlsx", errorHandler, args)
}

export function DownloadTemplate(errorHandler?: (errorMsg: string) => void) {
    return requestFileGet(P.DownloadTemplate, "Shablon", "xlsx", errorHandler)
}

export function GetScenarios(args: getScenariosRequest) {
    return requestPost<getScenariosResponse>(P.Scenarios, args)
}

export function GetDistributionInfo(args: getDistributionInfoRequest) {
    return requestPost<getDistributionInfoResponse>(P.Distribution, args)
}

export function AutoDistribute(args: autoDistributeRequest) {
    return requestPost<autoDistributeResponse>(P.AutoDistribute, args)
}

export function MakeOrder(args: makeOrderRequest) {
    return requestPost<makeOrderResponse>(P.MakeOrder, args)
}
