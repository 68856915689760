import { FC } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../UI/buttons/Button";

interface IProps {
  onAction?: () => void
  disabled?: boolean

  isOpen?: boolean
  toggleOpen: (open: boolean) => void;

  onActionText?: string
  onAbortText?: string

  headerText?: string
  text?: string
}

const ActionModal: FC<IProps> = ({
  headerText,
  isOpen: open,
  onAbortText,
  onAction,
  onActionText,
  toggleOpen: toggleOpen,
  text,
  disabled
}) => {
  const highlightText = (text: string): JSX.Element => {
    const emailRegex = /(\b[\w\.-]+@[\w\.-]+\.\w+\b)/g;
    const highlightedText = text.replace(emailRegex, '<span class="font-normal text-black">$1</span>');
    return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  };
  return (
    <ModalBase onOpenChange={toggleOpen} open={open}>
      <div className="flex flex-col max-w-[380px]">
        <div className="w-full flex justify-end -mb-4 ml-2">
          <IconButton onClick={() => { toggleOpen(false) }} className="-translate-y-4 translate-x-4" size="table">
            <CrossIcon />
          </IconButton>
        </div>
        <div className="">
          {headerText && (
            <div className="font-semibold text-lg mb-3">{headerText}</div>
          )}
          {text && (
            <div className="font-normal text-sm text-gray-4">{highlightText(text)}</div>
          )}
          <div className="flex mt-6">
            {onAction && (
              <Button disabled={disabled} onClick={onAction} variant="primary" className="w-fit mr-4" size="big" rounded="small" noFish>{onActionText}</Button>
            )}
            {onAbortText && (
              <Button variant="secondary" onClick={() => { toggleOpen(false) }} className="w-fit" size="big" rounded="small" noFish>{onAbortText}</Button>
            )}
          </div>
        </div>
      </div>
    </ModalBase>
  );
}

export default ActionModal;