import { Dispatch, FC, SetStateAction } from "react";
import ReferenceTable from "../../../../UI/table/ReferenceTable";
import LinkButton from "../../../../UI/buttons/LinkButton";
import RefreshIcon from "../../../../../assets/icons/RefreshIcon";
import IconButton from "../../../../UI/buttons/IconButton";
import BasketIcon from "../../../../../assets/icons/BasketIcon";
import SpekaIcon from "../../../../../assets/icons/SpekaIcon";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "../../../../../assets/icons/ArrowLeftIcon";
import { IOrderBasket, IOrder } from "../../../../../assets/interfaces/IOrder";
import AuthorizedRoutes from "../../../../../routes/routes";
import { ExchangeNavigateEvent } from "../../ExchangePage/ExchangePage";
import { IReference } from "../../../../../assets/interfaces/IReference";
import { onDownloadSpec } from "../../utils/utils";
import Button from "../../../../UI/buttons/Button";

interface IProps {
  orders: IOrder[] | undefined
  setExpandOrder: Dispatch<SetStateAction<IOrder | undefined>>

}

const OrderHistoryTable: FC<IProps> = ({
  orders,
  setExpandOrder,
}) => {
  const navigate = useNavigate();


  const onNavigateToExchange = (eventType: "repeatOrder" | "makeBasket", order: IOrder) => {
    navigate(AuthorizedRoutes.Exchange, { state: { eventType: eventType, order: order } as ExchangeNavigateEvent })
  }

  const onDownloadFullSpec = (order: IOrder) => {
    const references: IReference[] = [];
    for (const basket of order.baskets) {
      for (const reference of basket.references) {
        references.push(reference);
      }
    }
    onDownloadSpec(references)
  }

  return (
    <div className="flex flex-col gap-5 px-[284px] pb-5">
      <div className="flex items-center gap-2 -ml-10 mt-6">
        <span
          onClick={() => {
            navigate("/");
          }}
          className="w-8 h-8 bg-gray-1 flex items-center justify-center rounded-lg text-accent cursor-pointer 
                hover:text-accent-dark hover:bg-gray-2 transition-all"
        >
          <ArrowLeftIcon size={16} />
        </span>
        <div className="flex justify-between w-full">
          <h1 className="text-2xl font-semibold">
            История заказов
          </h1>
        </div>
      </div>
      {orders && orders.length > 0 ?
        orders.map((order: IOrder, index: number) => (
          <div className="shadow-3 p-5 rounded-lg" key={index}>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-6 mb-5">
                <div className="text-base font-medium">
                  Заказ от {order.creationDate}
                </div>
                <div className="flex gap-2">
                  <IconButton onClick={() => onNavigateToExchange("repeatOrder", order)} variant="secondary"><RefreshIcon className="text-accent" size={20} /></IconButton>
                  <IconButton onClick={() => onNavigateToExchange("makeBasket", order)} variant="secondary"><BasketIcon className="text-accent" size={20} /></IconButton>
                  <IconButton onClick={() => { onDownloadFullSpec(order) }} variant="secondary"><SpekaIcon className="text-accent" size={20} /></IconButton>
                </div>
              </div>
              <LinkButton onClick={() => { setExpandOrder(order) }} className="text-sm font-normal" noFish>
                Подробнее
              </LinkButton>
            </div>
            <div className="flex flex-col gap-4">
              {order.baskets.map((basket: IOrderBasket, index: number) => (
                <ReferenceTable
                  noBorder={true}
                  key={index}
                  companyAvatar={basket.partnerImg}
                  companyName={basket.partnerName}
                  references={basket.references}
                  noCount={true}
                />
              ))}
            </div>
          </div>
        )): <div className="h-[80vh] w-full flex items-center justify-center flex-col gap-3">
          <h1 className="text-2xl">История заказов пуста</h1>
          <Button onClick = {() => navigate(AuthorizedRoutes.Exchange)} noFish className="w-auto rounded-lg">Сделать заказ</Button>
        </div> }
    </div>
  );
}

export default OrderHistoryTable;