import * as LabelBase from "@radix-ui/react-label";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as SelectBase from "@radix-ui/react-select";
import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";

import SearchBox from "../textInput/SearchBox";
import InfoIcon from "../../../../assets/icons/InfoIcon";
import ArrowRightIcon from "../../../../assets/icons/ArrowRightIcon";
import { getRandomId } from "../../../../utils/reusedFunks";

type selectSize = "big" | "small" | "xs";

const sizeClasses = {
	big: "px-3 min-h-[3rem]",
	small: "px-3 min-h-[2.25rem]",
	xs: "px-1.5 min-h-[1.75rem]",
};

interface Props<T> {
	label?: string;
	noLabel?: boolean;
	placeholder?: string;
	size?: selectSize;
	searchable?: boolean;



	arrowIcon?: ReactNode;
	postArrowIcon?: ReactNode;
	preTextIcon?: ReactNode;

	error?: boolean;
	showErrorIcon?: boolean;
	description?: string;

	value?: string;
	onValueChange?(value: string): void;
	disabled?: boolean;

	items: T[];
	getItemTitle(item: T): string;
	getItemValue(item: T): string;
	getItemDisabled?(item: T): boolean | undefined;
	getItemHidden?(item: T): boolean;
	getItemClasses?(item: T): string | undefined;
	getHighLightItem?(item: T): boolean;
	getLowLightItem?(item: T): boolean;
	noValue?: boolean;
	highLight?: boolean;
	styleClasses?: {
		root?: string;
		trigger?: string;
		list?: string;
		scroll?: string;
		item?: string;
		errorIcon?: string;
	};
	className?: string
}

const Select = <T extends object>({ size = "big", ...props }: Props<T>) => {
	const id = getRandomId("sel");
	const [open, setOpen] = useState(false);

	const [search, setSearch] = useState("");
	const [itemVisibility, setItemVisibility] = useState<boolean[]>([]);

	useEffect(() => {
		if (search === "") {
			return setItemVisibility(props.items.map(() => true));
		} else {
			setItemVisibility(
				props.items.map((item) =>
					props.getItemTitle(item).toLowerCase().includes(search.toLowerCase()),
				),
			);
		}
	}, [props.items, search]);

	useEffect(() => {
		if (!open) {
			setSearch("");
		}
	}, [open]);

	return (
		<SelectBase.Root
			disabled={props.disabled}
			value={props.value}
			onOpenChange={(open) => setOpen(open)}
			onValueChange={props.onValueChange}
		>
			<div className={clsx("flex flex-col gap-1", props.styleClasses?.root)}>
				{!props.noLabel && (
					<LabelBase.Root className="block text-xs text-gray-4" htmlFor={id}>
						{props.label}
					</LabelBase.Root>
				)}
				<SelectBase.Trigger
					className={clsx(
						"flex w-full items-center justify-between gap-1 rounded-lg border border-gray-2 text-sm outline-none",
						"disabled:bg-gray-1 data-[placeholder]:text-gray-3",
						sizeClasses[size],
						props.styleClasses?.trigger,
						props.highLight && "!border-2 !border-accent",
						props.error && "!border-system-red",
						props.className
					)}
					id={id}
				>
					<span className="flex items-center gap-1 [&>*]:text-left">
						{props.preTextIcon}
						{props.noValue ? props.placeholder : <SelectBase.Value placeholder={props.placeholder} />}
					</span>
					<span className="flex items-center gap-1">
						<SelectBase.Icon
							className={clsx("transition-all", open ? "-rotate-90" : "rotate-90")}
						>
							{props.arrowIcon || <ArrowRightIcon className="text-gray-3" size={16} />}
						</SelectBase.Icon>
						{props.showErrorIcon ? (
							<InfoIcon
								className={props.styleClasses?.errorIcon || "text-system-red"}
							/>
						) : null}
					</span>
				</SelectBase.Trigger>
			</div>
			<SelectBase.Portal>
				<SelectBase.Content
					className={clsx(
						"my-2 rounded-lg bg-white py-1 text-sm shadow-1",
						"w-[--radix-select-trigger-width]",
						props.styleClasses?.list,
					)}
					position="popper"
				>
					<ScrollArea.Root className="h-full w-full" type="hover">
						{props.searchable ? (
							<div className="flex p-3">
								<SearchBox
									inputSize="small"
									placeholder="Поиск"
									onChangeText={(text: string) => setSearch(text)}
									onKeyDown={(e) => e.stopPropagation()}
								/>
							</div>
						) : null}
						<SelectBase.Viewport asChild>
							<ScrollArea.Viewport
								className={clsx("h-full w-full", props.styleClasses?.scroll)}
								style={{ overflowY: undefined }}
							>
								{props.items.map((item, ind) => {
									return (
										<SelectBase.Item
											key={`item-${ind}`}
											className={clsx(
												"flex cursor-pointer items-center px-3 py-1.5 outline-none hover:bg-gray-1",
												"first:rounded-t-sm last:rounded-b-sm",
												(!itemVisibility[ind] || props.getItemHidden?.(item)) &&
												"hidden",
												props.styleClasses?.item,
												props.getItemClasses?.(item),
												props.getHighLightItem &&
												props.getHighLightItem(item) &&
												"rounded-xl border !border-accent !bg-accent-transparent",
												props.getLowLightItem &&
												props.getLowLightItem(item) &&
												"bg-gray-1",
											)}
											disabled={props.getItemDisabled?.(item)}
											value={props.getItemValue(item)}
										>
											<SelectBase.ItemText>
												{props.getItemTitle(item).toString()}
											</SelectBase.ItemText>
										</SelectBase.Item>
									);
								})}
							</ScrollArea.Viewport>
						</SelectBase.Viewport>
						<ScrollArea.Scrollbar
							className={clsx(
								"flex touch-none select-none p-1 transition-colors ease-out",
								"data-[orientation=horizontal]:h-3 data-[orientation=vertical]:w-3 data-[orientation=horizontal]:flex-col",
							)}
							orientation="vertical"
						>
							<ScrollArea.Thumb
								className={clsx(
									"relative w-1 flex-1 rounded-[10px] bg-gray-1",
									"before:absolute before:left-1/2 before:top-1/2",
									"before:h-full before:min-h-[44px] before:w-full before:min-w-[44px]",
									'before:-translate-x-1/2 before:-translate-y-1/2 before:content-[""]',
								)}
							/>
						</ScrollArea.Scrollbar>
					</ScrollArea.Root>
				</SelectBase.Content>
			</SelectBase.Portal>
		</SelectBase.Root>
	);
};

export default Select;
