import { ISvg } from "../interfaces/ISvg";

function UserIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.25 8C6.25 4.82436 8.82436 2.25 12 2.25C15.1756 2.25 17.75 4.82436 17.75 8C17.75 11.1756 15.1756 13.75 12 13.75C8.82436 13.75 6.25 11.1756 6.25 8ZM12 3.75C9.65279 3.75 7.75 5.65279 7.75 8C7.75 10.3472 9.65279 12.25 12 12.25C14.3472 12.25 16.25 10.3472 16.25 8C16.25 5.65279 14.3472 3.75 12 3.75Z" 
      fill="currentColor" />
      <path d="M12 15.25C9.67278 15.25 7.53029 15.8381 5.94565 16.8285C4.37157 17.8123 3.25 19.2674 3.25 21C3.25 21.4142 3.58579 21.75 4 21.75C4.41421 21.75 4.75 21.4142 4.75 21C4.75 19.9712 5.41929 18.9263 6.74064 18.1005C8.05143 17.2812 9.90894 16.75 12 16.75C14.0911 16.75 15.9486 17.2812 17.2594 18.1005C18.5807 18.9263 19.25 19.9712 19.25 21C19.25 21.4142 19.5858 21.75 20 21.75C20.4142 21.75 20.75 21.4142 20.75 21C20.75 19.2674 19.6284 17.8123 18.0544 16.8285C16.4697 15.8381 14.3272 15.25 12 15.25Z" 
      fill="currentColor" />
    </svg>
  );
}

export default UserIcon;