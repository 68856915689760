import { ISvg } from "../interfaces/ISvg";

function TelegramIcon({ size = 24, className }: ISvg) {
    return (
        <svg width={size} height={size} className={className} viewBox="0 0 23 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.2116 19.344C18.5305 19.921 19.0251 18.712 19.0251 18.712L22.5148 1.18117C22.4873 -0.00037086 20.8936 0.714049 20.8936 0.714049L1.35696 8.3803C1.35696 8.3803 0.422721 8.7101 0.505151 9.2871C0.587581 9.8641 1.32948 10.1389 1.32948 10.1389L6.248 11.7876C6.248 11.7876 7.7318 16.6511 8.034 17.5854C8.3088 18.4921 8.5561 18.5196 8.5561 18.5196C8.8309 18.6295 9.0782 18.4372 9.0782 18.4372L12.2656 15.552L17.2116 19.344ZM18.0633 4.2863C18.0633 4.2863 18.7502 3.8741 18.7227 4.2863C18.7227 4.2863 18.8326 4.3412 18.4754 4.7259C18.1457 5.0556 10.3695 12.035 9.3253 12.9692C9.2429 13.0242 9.1879 13.1066 9.1879 13.2165L8.8857 15.7994C8.8307 16.0742 8.4735 16.1017 8.3911 15.8544L7.0996 11.6228C7.0447 11.458 7.0996 11.2656 7.2645 11.1557L18.0633 4.2863Z" fill="currentColor" />
        </svg>
    );
}

export default TelegramIcon;