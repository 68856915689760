import { Dispatch, FC, SetStateAction } from "react";
import OrderHistoryItem from "./UI/OrderHistoryItem";
import ArrowLeftIcon from "../../../../../assets/icons/ArrowLeftIcon";
import Button from "../../../../UI/buttons/Button";
import SpekaIcon from "../../../../../assets/icons/SpekaIcon";
import RefreshIcon from "../../../../../assets/icons/RefreshIcon";
import BasketIcon from "../../../../../assets/icons/BasketIcon";
import BuyIcon from "../../../../../assets/icons/BuyIcon";
import { IOrder, IOrderBasket } from "../../../../../assets/interfaces/IOrder";
import { useNavigate } from "react-router-dom";
import AuthorizedRoutes from "../../../../../routes/routes";
import { ExchangeNavigateEvent } from "../../ExchangePage/ExchangePage";
import { onDownloadSpec } from "../../utils/utils";
import { IReference } from "../../../../../assets/interfaces/IReference";
import AppRoutes from "../../../../../routes/routes";
import systemeIcon from "../../../../../assets/systemeIcon.svg"

interface IProps {
  order: IOrder
  setExpandOrder: Dispatch<SetStateAction<IOrder | undefined>>
  isNewOrder?: boolean
}

const ExpandOrder: FC<IProps> = ({
  order,
  setExpandOrder,
  isNewOrder
}) => {

  const navigate = useNavigate();

  const onNavigateToExchange = (eventType: "repeatOrder" | "makeBasket") => {
    navigate(AuthorizedRoutes.Exchange, { state: { eventType: eventType, order: order } as ExchangeNavigateEvent })
  }

  const onDownloadFullSpec = (order: IOrder) => {
    const references: IReference[] = [];
    for (const basket of order.baskets) {
      for (const reference of basket.references) {
        references.push(reference);
      }
    }
    onDownloadSpec(references)
  }

  return (
    <div className="flex flex-1 flex-col w-full pb-16">
      <div>
        <div className="flex items-center gap-2 -ml-10 mt-6 px-[284px]">
          <span
            onClick={() => {
              setExpandOrder(undefined);
            }}
            className="w-8 h-8 bg-gray-1 flex items-center justify-center rounded-lg text-accent cursor-pointer 
                hover:text-accent-dark hover:bg-gray-2 transition-all"
          >
            <ArrowLeftIcon size={16} />
          </span>
          <div className="w-full">
            <div className="flex justify-between w-full">
              <h1 className="text-2xl font-semibold">
                {("Заказ " + order.creationDate)}
              </h1>
              {(order?.isActive) &&
                <div className="flex">
                  <Button onClick={() => { onDownloadFullSpec(order) }} size="small" variant="secondary" rounded="small" className="w-fit mr-2" noFish><SpekaIcon />Скачать спецификацию</Button>
                  <Button onClick={() => isNewOrder ? setExpandOrder(undefined) : navigate(AppRoutes.Exchange)} size="small" rounded="small" className="w-fit" noFish>Новый заказ</Button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 px-[284px] pb-5">
        {order.baskets.map((b: IOrderBasket, index: number) => (
          <div key={index}>
            <div className="flex justify-between mt-6">
              <div className="flex gap-2 items-center pb-4 border-b border-gray-1 mb-4">
                <img className="w-6 h-6 select-none" src={b.partnerImg || systemeIcon} alt="IMG" />
                <p className="text-base font-medium">{b.partnerName}</p>
              </div>
              {(order.isActive && b.buyLink) &&
                <a className="h-fit" target="_blank" href={b.buyLink}  onClick={(e) => e.stopPropagation()}>
                  <div className="flex items-baseline">
                    <div className="flex items-center gap-1 text-accent hover:text-accent-dark transition-all cursor-pointer select-none">
                      Купить
                      <BuyIcon size={16} />
                    </div>
                  </div>
                </a>
              }
            </div>
            <OrderHistoryItem refs={b.references} />
          </div>
        ))}
      </div>
      {(!order?.isActive) &&
        <div className="fixed bottom-0 left-0 w-full h-16 flex items-center justify-between shadow-4 gap-10 px-[284px] bg-white">
          <div className="flex">
            <Button onClick={() => onNavigateToExchange("repeatOrder")} size="small" rounded="small" className="w-fit mr-2" noFish><RefreshIcon />Повторить заказ</Button>
            <Button onClick={() => onNavigateToExchange("makeBasket")} size="small" variant="secondary" rounded="small" className="w-fit" noFish><BasketIcon /> Сформировать корзину на основе заказа</Button>
          </div>
          <Button onClick={() => { onDownloadFullSpec(order) }} size="small" variant="tetriary" rounded="small" className="w-fit" noFish><SpekaIcon /> Скачать спецификацию</Button>
        </div>
      }
    </div>
  );
}

export default ExpandOrder;
