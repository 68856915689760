import { ISvg } from "../interfaces/ISvg";

function PlanshetIcon({ size, className }: ISvg) {
    return (
        <svg width={size}
            height={size}
            className={className} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M25.8 32C25.8 29.7909 27.5909 28 29.8 28H30.2001C32.4092 28 34.2001 29.7909 34.2001 32C34.2001 34.2091 32.4092 36 30.2001 36H29.8C27.5909 36 25.8 34.2091 25.8 32Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.8 48C25.8 45.7909 27.5909 44 29.8 44H30.2001C32.4092 44 34.2001 45.7909 34.2001 48C34.2001 50.2091 32.4092 52 30.2001 52H29.8C27.5909 52 25.8 50.2091 25.8 48Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.8 64C25.8 61.7909 27.5909 60 29.8 60H30.2001C32.4092 60 34.2001 61.7909 34.2001 64C34.2001 66.2091 32.4092 68 30.2001 68H29.8C27.5909 68 25.8 66.2091 25.8 64Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16 15C15.7348 15 15.4804 15.1054 15.2929 15.2929C15.1054 15.4804 15 15.7348 15 16V80C15 80.2652 15.1054 80.5196 15.2929 80.7071C15.4804 80.8946 15.7348 81 16 81H80C80.2652 81 80.5196 80.8946 80.7071 80.7071C80.8946 80.5196 81 80.2652 81 80V16C81 15.7348 80.8946 15.4804 80.7071 15.2929C80.5196 15.1054 80.2652 15 80 15H16ZM11.0503 11.0503C12.363 9.7375 14.1435 9 16 9H80C81.8565 9 83.637 9.7375 84.9498 11.0503C86.2625 12.363 87 14.1435 87 16V80C87 81.8565 86.2625 83.637 84.9498 84.9498C83.637 86.2625 81.8565 87 80 87H16C14.1435 87 12.363 86.2625 11.0503 84.9498C9.7375 83.637 9 81.8565 9 80V16C9 14.1435 9.7375 12.363 11.0503 11.0503ZM45 32C45 30.3431 46.3431 29 48 29H68C69.6569 29 71 30.3431 71 32C71 33.6569 69.6569 35 68 35H48C46.3431 35 45 33.6569 45 32ZM45 48C45 46.3431 46.3431 45 48 45H68C69.6569 45 71 46.3431 71 48C71 49.6569 69.6569 51 68 51H48C46.3431 51 45 49.6569 45 48ZM45 64C45 62.3431 46.3431 61 48 61H68C69.6569 61 71 62.3431 71 64C71 65.6569 69.6569 67 68 67H48C46.3431 67 45 65.6569 45 64Z" fill="currentColor" />
        </svg>


    );
}

export default PlanshetIcon;
