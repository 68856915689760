import { ISvg } from "../interfaces/ISvg";


export default function CheckMarkIcon({ size = 24, className }: ISvg) {
	return (
		<svg
			className={className}
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M6.31098 10.4662L6.66453 10.8197L7.01808 10.4662L12.7928 4.69148L13.0285 4.92718L6.66453 11.2911L3.12899 7.75563L3.36468 7.51993L6.31098 10.4662Z" fill="black" stroke="currentColor" />
		</svg>


	);
}