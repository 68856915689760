import { useEffect, useState, useCallback } from "react";
import { FC } from "react";
import { IOrder } from "../../../../assets/interfaces/IOrder";
import OrderHistoryTable from "./components/OrderHistoryTable";
import ExpandOrder from "./components/ExpandOrder";
import ScrollArea from "../../../UI/wrappers/ScrollArea";
import { ResponseContainer } from "../../../../API/BaseApi";
import { GetHistoryResponse } from "../../../../API/base/historyApi/historyInterfaces";
import { GetHistory } from "../../../../API/base/historyApi/historyFunctions";
import Loader from "../../../UI/loader/loader";

interface IProps { 
  
}


const OrderHistoryPage: FC<IProps> = ({

}) => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [expandOrder, setExpandOrder] = useState<IOrder | undefined>();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    onGetOrders();
  }, []);

  const onGetOrders = useCallback(async () => {
    if (page === 0) setIsLoading(true);
    const response: ResponseContainer<GetHistoryResponse> = await GetHistory({ pagination: { page: page, pageSize: 10 } });
    if (response.data) {
      //@ts-ignore
      setOrders(prevOrders => [...prevOrders, ...response.data.orders as IOrder[]]);
      setTotalCount(response.data.totalCount);
      setPage(prevPage => prevPage + 1);
    }
    setIsLoading(false);
  }, [isLoading, page]);

  const scrollHandler = () => {
    if (expandOrder) {
      return;
    }
    if (orders.length < totalCount && isLoading==false) {
      onGetOrders();
    }
  };

  return (
    <div className="flex flex-col overflow-hidden h-[calc(100%_-_52px)] w-full">
      {!isLoading ? <ScrollArea onScrollEnd={scrollHandler}>
        {
          (expandOrder) ?
            <ExpandOrder setExpandOrder={setExpandOrder} order={expandOrder} />
            :
            <OrderHistoryTable setExpandOrder={setExpandOrder} orders={orders} />
        }
      </ScrollArea>: <Loader/>}
    </div>
  );
};

export default OrderHistoryPage;
