import { ISvg } from "../interfaces/ISvg";

function EyeOpenedIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5ZM9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12Z" 
      fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 4.5C8.00057 4.5 5.19504 6.3068 3.40717 8.09467C2.36295 9.13889 1.42651 10.3392 0.816307 11.6917L0.815334 11.6938L0.814641 12.3046L0.815334 12.3062C1.42237 13.6618 2.3621 14.8603 3.40717 15.9053C5.19504 17.6932 8.00057 19.5 12 19.5C15.9994 19.5 18.805 17.6932 20.5928 15.9053C21.6234 14.8748 22.5798 13.6475 23.1837 12.3083C23.2688 12.1182 23.2695 11.8842 23.1847 11.6938C22.5848 10.3535 21.6223 9.12416 20.5928 8.09467C18.805 6.3068 15.9994 4.5 12 4.5ZM2.33859 12C2.62526 12.537 3.31829 13.6951 4.46783 14.8447C6.05496 16.4318 8.49943 18 12 18C15.5006 18 17.945 16.4318 19.5322 14.8447C20.6817 13.6951 21.3747 12.537 21.6614 12C21.3747 11.463 20.6817 10.3049 19.5322 9.15533C17.945 7.5682 15.5006 6 12 6C8.49943 6 6.05496 7.5682 4.46783 9.15533C3.63605 9.98711 2.89438 10.9589 2.33859 12Z" 
      fill="currentColor" />
      <path d="M0.814641 11.6954C0.728453 11.8893 0.728453 12.1107 0.814641 12.3046V12V11.6954Z" 
      fill="currentColor" />
    </svg>
  );
}

export default EyeOpenedIcon;