import * as RadioGroupBase from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import Tooltip from '../Tooltip/Tooltip';

export interface RadioProps<T> {
	value?: string;
	onValueChange?(value: string): void;

	defaultValue?: string

	items: T[];
	getItemTitle(item: T): string;
	getItemValue(item: T): string;
	getItemDisabled?(item: T): boolean;
	getItemTooltip?(item: T): string | undefined;

	styleClasses?: {
		root?: string;
		item?: string;
	};
}

const CustomRadioBase = <T extends object>(props: RadioProps<T>) => {
	return (
		<RadioGroupBase.Root
			className={clsx('flex h-full', props.styleClasses?.root)}
			defaultValue={props.defaultValue ||
				(props.items[0] && props.getItemValue(props.items[0]))
			}
			value={props.value}
			onValueChange={props.onValueChange}
		>
			{props.items.map((item, ind) => {
				const tooltip = props.getItemTooltip?.(item);
				const content = (
					<RadioGroupBase.Item
						className={clsx(
							'cursor-pointer outline-none data-[disabled]:cursor-default data-[disabled]:text-gray-2',
							props.styleClasses?.item
						)}
						value={props.getItemValue(item)}
						disabled={props.getItemDisabled?.(item)}
						key={`item-${ind}`}
					>
						{props.getItemTitle(item)}
					</RadioGroupBase.Item>
				);
				return (
					<div key={`radio-${ind}`}>
						{tooltip ? (
							<Tooltip text={tooltip}>
								{content}
							</Tooltip>
						) : (
							content
						)}
					</div>
				);
			})}
		</RadioGroupBase.Root>
	);
};

export default CustomRadioBase;
