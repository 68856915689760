import { toast } from "react-toastify";
import { DownloadSpec } from "../../../../API/base/exchangeApi/exchangeFunctions";
import { IReference } from "../../../../assets/interfaces/IReference";

export const onDownloadSpec = (references: IReference[]) => {
    const refsData = references.map((r) => {
        return { referenceSku: r.referenceSku, count: r.count as number };
    });
    DownloadSpec({ references: refsData }, (msg: string) => {
        toast.error(msg);
    });
};

export const onTextCopy = (copyItem: string, itemName: string) => {
    navigator.clipboard.writeText(copyItem);
    toast.success(itemName + " скопирован");
};

export const onGeneratePassword = () => {
    const numbers = "0123456789";
    const upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const regexUpper = /[A-Z]{1, 7}$/g;
    const regexLower = /[a-z]{1, 7}$/g;
    const regexDigit = /[0-9]{1, 7}$/g;
    const passwordLenght = 18;
    let generatedPassword = "";

    const characterList = numbers + upperCaseLetters + lowerCaseLetters;
    const characterListLength = characterList.length;

    const generateRandom = () =>
        crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;

    if (!regexLower.test(generatedPassword)) {
        const characterIndex = Math.round(
            generateRandom() * lowerCaseLetters.length
        );
        generatedPassword =
            generatedPassword + lowerCaseLetters.charAt(characterIndex);
    }
    if (!regexUpper.test(generatedPassword)) {
        const characterIndex = Math.round(
            generateRandom() * upperCaseLetters.length
        );
        generatedPassword =
            generatedPassword + upperCaseLetters.charAt(characterIndex);
    }
    if (!regexDigit.test(generatedPassword)) {
        const characterIndex = Math.round(generateRandom() * numbers.length);
        generatedPassword = generatedPassword + numbers.charAt(characterIndex);
    }

    for (let i = 0; i < passwordLenght; i++) {
        const characterIndex = Math.round(
            generateRandom() * characterListLength
        );
        generatedPassword =
            generatedPassword + characterList.charAt(characterIndex);
    }

    return generatedPassword;
};