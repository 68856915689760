import { ISvg } from "../interfaces/ISvg";

function ArrowLeftIcon({size, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697L14.7197 4.46967C15.0126 4.17678 15.4874 4.17678 15.7803 4.46967C16.0732 4.76256 16.0732 5.23744 15.7803 5.53033L9.31066 12L15.7803 18.4697C16.0732 18.7626 16.0732 19.2374 15.7803 19.5303C15.4874 19.8232 15.0126 19.8232 14.7197 19.5303L7.71967 12.5303Z" 
        fill="currentColor" />
    </svg>
  );
}

export default ArrowLeftIcon;