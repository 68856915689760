import { ISvg } from "../interfaces/ISvg";

function YouTubeIcon({ size = 24, className }: ISvg) {
    return (
        <svg width={size} height={size} className={className} viewBox="0 0 26 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.38913 16.7625C2.95764 16.6348 2.56394 16.4077 2.24124 16.1003C1.91855 15.793 1.67633 15.4143 1.53503 14.9964C0.727604 12.8361 0.488365 3.81562 2.04341 2.03488C2.56096 1.45559 3.29167 1.09894 4.07694 1.04234C8.24866 0.604448 21.1376 0.662833 22.6179 1.1883C23.0343 1.32016 23.4146 1.54228 23.7306 1.83803C24.0465 2.13379 24.2897 2.4955 24.4421 2.89606C25.3243 5.12929 25.3542 13.2448 24.3225 15.3905C24.0488 15.949 23.5906 16.4014 23.0216 16.6749C21.4666 17.4339 5.45256 17.4193 3.38913 16.7625ZM10.013 12.5296L17.4892 8.73456L10.013 4.91034V12.5296Z" fill="currentColor" />
        </svg>
    );
}

export default YouTubeIcon;