import { ISvg } from "../interfaces/ISvg";

function ArrowRightIcon({size, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.21967 4.21967C8.51256 3.92678 8.98744 3.92678 9.28033 4.21967L16.2803 11.2197C16.5732 11.5126 16.5732 11.9874 16.2803 12.2803L9.28033 19.2803C8.98744 19.5732 8.51256 19.5732 8.21967 19.2803C7.92678 18.9874 7.92678 18.5126 8.21967 18.2197L14.6893 11.75L8.21967 5.28033C7.92678 4.98744 7.92678 4.51256 8.21967 4.21967Z"
        fill="currentColor" />
    </svg>
  );
}

export default ArrowRightIcon;