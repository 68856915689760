import { FC } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import LinkButton from "../UI/buttons/LinkButton";
import ShablonIcon from "../../assets/icons/ShablonIcon";
import ScrollArea from "../UI/wrappers/ScrollArea";
import Button from "../UI/buttons/Button";
import ExcelIcon from "../../assets/icons/ExcelIcon";
import { ImportError } from "../../API/base/exchangeApi/exchangeInterfaces";
import { DownloadTemplate } from "../../API/base/exchangeApi/exchangeFunctions";
import { toast } from "react-toastify";

interface IProps {
  errors: ImportError[],
  skipFunk: () => void,
  retryFunk: () => void,

  onOpenChange: (open: boolean) => void;
  open: boolean
}

export const ImportErrorModal: FC<IProps> = ({
  onOpenChange,
  open,
  errors,
  retryFunk,
  skipFunk,
}) => {

  const onDownloadTemplateError = (msg: string) => {
    toast.error(msg)
  }
  return (
    <ModalBase className="!p-0" onOpenChange={onOpenChange} open={open}>
      <div className="w-[648px]">
        <div className="pt-10 px-10">
          <div className="flex justify-end -mb-2 ml-2">
            <IconButton onClick={() => { onOpenChange(false) }} className="-translate-y-4 translate-x-4" size="small">
              <CrossIcon size={16} />
            </IconButton>
          </div>
          <div className="mb-6">
            <div className="font-semibold text-lg mb-4">
              Ошибки импорта
            </div>
            <LinkButton onClick={() => DownloadTemplate(onDownloadTemplateError)} noFish>
              <ShablonIcon />
              Скачать шаблон заполнения
            </LinkButton>
          </div>
        </div>
        <ScrollArea enableHorizontal className="max-h-[400px]">
          <div className="px-10 pb-4">
            <table className="w-full">
              <thead>
                <tr className="text-xs text-gray-4 text-left font-semibold">
                  <th className="p-2">№ Строки</th>
                  <th className="p-2">Значение ячейки</th>
                  <th className="p-2">Тип ошибки</th>
                </tr>
              </thead>
              <tbody className="">
                {errors.map((error) => (
                  <tr key={error.rowNumber} className="text-xs font-normal min-w-[648px]">
                    <td className="border border-gray-2 p-2">{error.rowNumber}</td>
                    <td className="border border-gray-2 p-2">{error.cellValue}</td>
                    <td className="border border-gray-2 p-2">{error.errorType}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ScrollArea>
        <div className="w-full flex px-10 py-4">
          <Button onClick={retryFunk} size="big" rounded="small" className="w-fit mr-4" noFish>
            <ExcelIcon />
            Импортировать повторно</Button>
          <Button onClick={skipFunk} variant="secondary" size="big" rounded="small" className="w-fit" noFish>Пропустить ошибки</Button>
        </div>
      </div>
    </ModalBase>
  );
}

export default ImportErrorModal;