import { FC } from "react"
import BigStep from "./BigStep"
import laptopStepOne from "../../../../../assets/images/laptopStepOne.png"
import laptopStepTwo from "../../../../../assets/images/laptopStepTwo.png"
import laptopStepThree from "../../../../../assets/images/laptopStepThree.png"
import laptopStepFour from "../../../../../assets/images/laptopStepFour.png"

interface IProps {
    
}
const StepsBig: FC<IProps> = (props:IProps) => {
	return (<div className="w-full flex flex-col gap-12 sm:gap-6">
		<BigStep step={1} stepsCout={4} headText="Выбор товаров" imgIsLeft img={laptopStepOne} text="Выберите товары Systeme Electric и добавьте их в корзину"/>
		<BigStep step={2} stepsCout={4} headText="Проверка наличия" imgIsLeft = {false} img={laptopStepTwo} text="Сервис проверит наличие выбранных позиций у дистрибьюторов и предложит оптимальные сценарии для закупки"/>
		<BigStep step={3} stepsCout={4} headText="Выбор поставщиков" imgIsLeft img={laptopStepThree} text="Выберите один из предложенных сценариев закупки. Вы можете внести корректировки в сценарий, используя удобные фильтры"/>
		<BigStep step={4} stepsCout={4} headText="Закупка" imgIsLeft = {false} img={laptopStepFour} text="Ваша корзина товаров будет перенесена в интернет-магазин дистрибьютора для дальнейшей закупки товаров"/>
	</div>)
}

export default StepsBig