import clsx from "clsx";
import { ReactNode, forwardRef } from "react";
import * as TooltipBase from "@radix-ui/react-tooltip";


interface Props {
	children: ReactNode;
	notChild?: boolean;
	text?: string;
	side?: "top" | "right" | "bottom" | "left";
	open?: boolean;
	className?: string;
	dropChild?: ReactNode;
}

const Tooltip = forwardRef<HTMLButtonElement, Props>(
	({side = "top", ...props}: Props, ref) => {
		return (
			<TooltipBase.Provider>
				<TooltipBase.Root open = {props.open || undefined}>
				{props.notChild ? (
					<TooltipBase.Trigger className="cursor-default">
						{props.children}
					</TooltipBase.Trigger>
				) : (
					<TooltipBase.Trigger ref={ref} asChild>
						{props.children}
					</TooltipBase.Trigger>
				)}
				<TooltipBase.Portal>
					<TooltipBase.Content
						className={clsx(
							"z-50 max-w-[16rem] data-[state=delayed-open]:opacity-100",
							"leading-1 select-none rounded-[4px] bg-gray-4 px-2 py-1 text-xs text-white",
							props.className,
						)}
						side={side}
						sideOffset={5}
					>
						{props.text || props.dropChild}
						<TooltipBase.Arrow className="fill-gray-4" />
					</TooltipBase.Content>
				</TooltipBase.Portal>
			</TooltipBase.Root>
			</TooltipBase.Provider>
			
		);
	},
);

Tooltip.displayName = "Tooltip";

export default Tooltip;
