import { ISvg } from "../interfaces/ISvg";

function PlusIcon({size, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5C12.4276 5 12.7742 5.34662 12.7742 5.77419V11.2258H18.2258C18.6534 11.2258 19 11.5724 19 12C19 12.4276 18.6534 12.7742 18.2258 12.7742H12.7742V18.2258C12.7742 18.6534 12.4276 19 12 19C11.5724 19 11.2258 18.6534 11.2258 18.2258V12.7742H5.77419C5.34662 12.7742 5 12.4276 5 12C5 11.5724 5.34662 11.2258 5.77419 11.2258H11.2258V5.77419C11.2258 5.34662 11.5724 5 12 5Z"
      fill="currentColor" />
    </svg>
  );
}

export default PlusIcon;