import { Dispatch, FC, SetStateAction } from "react";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import MinusIcon from "../../../../assets/icons/MinusIcon";
import TextBox from "./TextBox";
import IconButton from "../../buttons/IconButton";
import clsx from "clsx";

interface IProps {
  setNumber:(count: number) => void;
  value: number,
  size: sizeType,
  disabled?: boolean,
  maxValue?: number,
  minValue?: number,
  disableIncrement?: boolean
}

type sizeType = "big" | "small" | "full"

const sizes = {
  big: "!h-8",
  small: "!h-6",
  full: '!h-full',
};

const iconSizes = {
  big: "!w-8",
  small: "!w-6",
  full: "!w-full"
};

const Counter: FC<IProps> = ({
  setNumber,
  value,
  size,
  disabled,
  maxValue,
  minValue,
  disableIncrement
}) => {
  const handleDecrement = () => {
    setNumber(value-1)
  }
  const handleIncrement = () => {
    setNumber(value+1)
  }
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = +e.target.value;
    if (!isNaN(inputValue)) {
      if (maxValue && inputValue > maxValue) {
        setNumber(maxValue);
        return
      }
      setNumber(inputValue);
    }
  }
  return (
    <div className='flex gap-1 items-center max-h-[32px]'>
      <IconButton onClick={handleDecrement} disabled={value === 0 || value === minValue} className={clsx(sizes[size], iconSizes[size], "!bg-gray-1 text-accent rounded-lg")}>
        <MinusIcon className="w-3 h-3" />
      </IconButton>
      <TextBox disabled={disabled} onChange={handleOnChange} value={value} className={clsx(sizes[size], "!p-0 text-center max-w-[52px]")} />
      <IconButton  onClick={handleIncrement} disabled={disabled || (value === maxValue) || disableIncrement} className={clsx(sizes[size], iconSizes[size], "!bg-gray-1 text-accent rounded-lg")}>
        <PlusIcon className="w-3 h-3" />
      </IconButton>
    </div>
  );
}

export default Counter;