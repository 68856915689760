import clsx from "clsx";
import { useState } from "react";

import FilterDropdown from "./FilterDropdown";
import ArrowRightIcon from "../../../../assets/icons/ArrowRightIcon";
import CrossIcon from "../../../../assets/icons/CrossIcon";

interface Props<T> {
    title: string;
    searchable?: boolean;
    items: T[];
    pickedValues: (string | number | boolean)[];

    getItemTitle(item: T): string;
    getItemImg?(item: T): string;
    getItemValue(item: T): string | number | boolean;
    scrollClassName?: string;
    containerClassName?: string;
    onCheck?(item: T): void;
    clearFunk: () => void;
}

const SelectFilter = <T extends object>(props: Props<T>) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={clsx(
            "flex items-center bg-gray-1 rounded-lg px-3 w-full gap-1",
            isOpen && "text-accent", props.pickedValues.length > 0 && "!bg-accent-lite "
        )}>
            <FilterDropdown
                styleClasses={{ search: "rounded-none !border-b-gray-2 border-t-0 border-l-0 border-r-0", scroll: props.scrollClassName, container: props.containerClassName}}
                getItemTitle={props.getItemTitle}
                getItemValue={props.getItemValue}
                getItemImg={props.getItemImg}
                items={props.items}
                pickedValues={props.pickedValues}
                searchable={props.searchable}
                notChild
                onCheck={props.onCheck}
                onOpenChange={setIsOpen}
            >
                <div
                    className={clsx(
                        "flex h-8 w-full flex-1 items-center gap-2 hover:text-accent select-none",
                        isOpen && "text-accent"
                    )}
                >
                    <p className="w-full transition-all">{props.title}</p>
                    {props.pickedValues.length === 0 ? <ArrowRightIcon
                    
                        className={clsx("transition-all !w-4 mt-[2px] text-gray-3", isOpen ? "-rotate-90" : "rotate-90")}
                        size={16}
                    /> : <span className="text-accent font-medium !w-4">{`${props.pickedValues.length}`}</span>}
                </div>
            </FilterDropdown>
            {props.pickedValues.length > 0 && <div onClick={props.clearFunk} className="cursor-pointer hover:text-accent text-gray-3"><CrossIcon size={13} className="transition-all" /></div>}
        </div>
    );
};

export default SelectFilter;
