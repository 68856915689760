import * as DropdownMenuBase from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import {forwardRef, ReactNode} from "react";

export interface DropdownProps {
	trigger: ReactNode;
	children: ReactNode;
	notChild?: boolean;
	align?: "center" | "end" | "start" | undefined;
	sideOffset?: number;

	open?: boolean;
	onOpenChange?(value: boolean): void;

	styleClasses?: {
		content?: string;
		item?: string;
		arrow?: string;
	};
}

export interface DropdownMenuProps {
	children: ReactNode;
	notChild?: boolean;
	align?: "center" | "end" | "start" | undefined;

	onOpenChange?(value: boolean): void;

	items: ReactNode[];

	styleClasses?: {
		content?: string;
		item?: string;
		arrow?: string;
	};
}

const DropdownBase = forwardRef<HTMLButtonElement, DropdownProps>(
	(props: DropdownProps, ref) => {
		return (
			<DropdownMenuBase.Root  open={props.open} onOpenChange={props.onOpenChange}>
				{props.notChild ? (
					<DropdownMenuBase.Trigger className="w-full outline-none">
						{props.trigger}
					</DropdownMenuBase.Trigger>
				) : (
					<DropdownMenuBase.Trigger className="cursor-pointer" ref={ref} asChild>
						{props.trigger}
					</DropdownMenuBase.Trigger>
				)}
				<DropdownMenuBase.Portal>
					<DropdownMenuBase.Content
						align={props.align}
						className={clsx(
							"flex flex-col rounded-lg bg-white text-sm shadow-2 outline-none",
							props.styleClasses?.content,
						)}
						sideOffset={props.sideOffset || 5}
						onClick={(e) => e.stopPropagation()}
					>
						{props.children}
					</DropdownMenuBase.Content>
				</DropdownMenuBase.Portal>
			</DropdownMenuBase.Root>
		);
	},
);

DropdownBase.displayName = "DropdownBase";

export default DropdownBase;
