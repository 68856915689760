const Loader = () => {
	return (
		<div className="flex h-full w-full items-center justify-center">
			<div
				className="border-current inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-r-transparent align-[-0.125em] text-accent motion-reduce:animate-[spin_1.5s_linear_infinite]"
				role="status"
			>
				<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
					Loading...
				</span>
			</div>
		</div>
	);
};

export default Loader;