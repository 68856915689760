import { FC, useEffect, useState } from "react";
import ScrollArea from "../../../../../UI/wrappers/ScrollArea";
import Button from "../../../../../UI/buttons/Button";
import IconButton from "../../../../../UI/buttons/IconButton";
import ArrowLeftIcon from "../../../../../../assets/icons/ArrowLeftIcon";
import { Basket, IScenario } from "../../../../../../assets/interfaces/IScenario";
import ConfirmModal from "../../../../../modals/ConfirmModal";
import UnllocatedBar from "./UnllocatedBar";
import { IReference, UnllocatedReference } from "../../../../../../assets/interfaces/IReference";
import { IPartner } from "../../../../../../assets/interfaces/IPartner";
import AddPartnersModal from "../../../../../modals/AddParntnersModal";
import { MakeOrder } from "../../../../../../API/base/exchangeApi/exchangeFunctions";
import Scenario from "./Scenario";
import { IOrder } from "../../../../../../assets/interfaces/IOrder";
import LoaderModal from "../../../../../UI/loader/LoaderModal";
import { toast } from "react-toastify";
interface IProps {
    backFunk: () => void;
    scenarioData: IScenario;
    basketReferences: IReference[],
    setMakedOrder: (newOrderData: IOrder) => void,
    getUnllocatedReferences: (items: UnllocatedReference[]) => void;
}

const WorkWithScenary: FC<IProps> = ({ backFunk, scenarioData, basketReferences, setMakedOrder, getUnllocatedReferences }) => {

    const getScenarioCopyForWork = (sData: IScenario) => {
        const newScenario = { ...sData }
        const sBaskets = newScenario.baskets.map((b) => {
            const basketRefs = b.references.map((r) => {
                return { ...r }
            })
            return { ...b, references: basketRefs }
        })
        return { ...newScenario, baskets: sBaskets }
    }
    const [backConfirmModal, setBackConfirmModal] = useState<boolean>(false)
    const [scenario, setScenario] = useState<IScenario>(getScenarioCopyForWork(scenarioData))
    const [scenarioPartners, setScenarioPartners] = useState<IPartner[]>([])
    const [addPartnersModal, setAddPartnersModal] = useState<boolean>(false)
    const [isUnllocated, setIsUnllocated] = useState<boolean>(false)
    const [makeOrderLoading, setMakeOrderLoading] = useState<boolean>(false)
    const [scenarioIsChanged, setScenarioIsChanged] = useState<boolean>(false)
    const [confirmOrederModal, setConfirmOrderModal] = useState<boolean>(false)
    const [scenarioPartnersLoaded, setScenarioPartnersLoaded] = useState<boolean>(false)

    useEffect(() => {
        if (scenario && scenarioData) {
            setScenarioIsChanged(!(JSON.stringify(scenario) === JSON.stringify(scenarioData)))
        }
    }, [scenario, scenarioData])

    useEffect(() => {
        onSetScenarioPartners()
        setScenario(getScenarioCopyForWork(scenarioData))
    }, [scenarioData])

    useEffect(() => {
        if (scenario && scenarioPartnersLoaded) {
            const scenarioPartnersIds = scenarioPartners.map((p) => p.id)
            const newScenarioBaskets = [...scenario.baskets].filter((basket: Basket) => scenarioPartnersIds.includes(basket.partnerId))
            setScenario((prev: IScenario) => ({ ...prev, baskets: newScenarioBaskets }))
        }
    }, [scenarioPartners])

    const onSetScenarioPartners = (basketList: Basket[] = scenarioData.baskets) => {
        const partners = basketList.map((b) => ({ avatar: b.partnerImg, name: b.partnerName, id: b.partnerId } as IPartner))
        setScenarioPartners(partners)
        setScenarioPartnersLoaded(true)
    }

    const getScenarioPartnersIdsArr = () => {
        return scenarioPartners.map((p) => p.id)
    }

    const onMakeOrder = () => {
        const baskets = scenario.baskets.map((b) => {
            const basketRefs = b.references.map((r) => {
                return {
                    referenceSku: r.referenceSku,
                    count: r.count as number,
                }
            }).filter((r) => r.count > 0)
            return { partnerId: b.partnerId, references: basketRefs }
        })
        setMakeOrderLoading(true)
        MakeOrder({ baskets: baskets }).then((r) => {
            if (r.status !== "error") {
                setMakedOrder(r.data?.order as IOrder)
            }
            else {
                toast.error("Не удалось сформировать заказ")
            }
            setMakeOrderLoading(false)
            setConfirmOrderModal(false)
        })
    }

    return (<div className="flex flex-col w-full h-full gap-3 relative pt-5">
        <div className="flex w-full items-center gap-2 px-9 h-9">
            <IconButton
                onClick={() => !scenarioIsChanged ? backFunk() : setBackConfirmModal(true)}
                variant="secondary"
                size="small">
                <ArrowLeftIcon size={16} />
            </IconButton>
            <h1 className="flex-1 text-lg font-semibold ml-2">Работа со сценарием</h1>
            <div>
                <Button
                    onClick={() => setAddPartnersModal(true)}
                    noFish
                    size="small"
                    variant="secondary"
                    className="rounded-lg w-auto">
                    <div className="w-5 bg-accent text-white rounded-2xl">{scenarioPartners.length}</div> Список партнеров
                </Button>
            </div>
            <div>
                <Button
                    disabled={scenario.baskets.length === 0}
                    onClick={isUnllocated ? () => setConfirmOrderModal(true) : onMakeOrder}
                    noFish size="small" className="rounded-lg w-auto">Сформировать заказ
                </Button>
            </div>
        </div>
        <div className="flex-1 w-full overflow-hidden flex items-center justify-center">
            <ScrollArea className="px-9 pt-4">
                <Scenario
                    onSetScenarioPartners={onSetScenarioPartners}
                    setScenarioData={setScenario}
                    isEdit={true}
                    data={scenario as IScenario}
                    scenarioPartnersLength={scenarioPartners.length}
                />
            </ScrollArea>
        </div>
        <UnllocatedBar
            setIsUnllocated={setIsUnllocated}
            onSetScenario={setScenario}
            scenarioPartners={scenarioPartners}
            basketReferences={basketReferences}
            scenarioData={scenario as IScenario}
            getUnllocatedReferences={getUnllocatedReferences}
        />
        <ConfirmModal
            confirmBtnText="Выйти"
            funk={backFunk}
            isOpen={backConfirmModal}
            toggleOpen={() => setBackConfirmModal(!backConfirmModal)}
            headText="Выйти из редактирования?"
            text="Вы уверены, что хотите выйти из редактирования сценария? Все ручные корректировки будут сброшены" />
        <ConfirmModal
            confirmBtnText="Заказать"
            funk={onMakeOrder}
            isOpen={confirmOrederModal}
            toggleOpen={() => setConfirmOrderModal(!confirmOrederModal)}
            headText="Оборудование не распределено!"
            text="В сценарии есть нераспределенное оборудование. Вы уверены, что хотите сделать заказ?" />
        {addPartnersModal && <AddPartnersModal addedPartnersIds={getScenarioPartnersIdsArr()}
            isOpen={addPartnersModal}
            toggleOpen={() => setAddPartnersModal(!addPartnersModal)}
            onSetScenarioPartners={setScenarioPartners}
        />}
        <LoaderModal open={makeOrderLoading} />
    </div>);
}

export default WorkWithScenary;