import Typography from '../Typography';
import IconButton from '../buttons/IconButton';
import CrossIcon from '../../../assets/icons/CrossIcon';
import Select from './dropdown/Select';


interface Props<T> {
  label?: string;
  items: T[]
  allItems: T[];
  placeholder?: string;
  getItemTitle(item: T): string;
  getItemValue(item: T): string;
  getItemByValue(value: string): T;
  onSelectItem(item: T): void;
  onDeleteItem(item: T): void;
}

const ChipsBox = <T extends Object>(props: Props<T>) => {
  return (
    <div className='flex flex-col gap-1.5 '>
      {props.label && <Typography size='h6' className='text-gray-3'>{props.label}</Typography>}
      <div className='flex w-full items-center flex-wrap border border-gray-2 rounded-lg'>
        <div className='flex flex-wrap gap-1 p-1'>
          {props.items.map((item, index) => (
            <div key={index} className='flex items-center gap-2 p-0.5 pl-4 rounded-lg bg-accent-transparent '>
              <Typography size='h5'>{props.getItemTitle(item)}</Typography>
              <IconButton onClick={(e) => { e.stopPropagation(); props.onDeleteItem(item) }}>
                <CrossIcon size={18} />
              </IconButton>
            </div>
          ))}
        </div>
        <Select
        className='p'
          searchable
          noValue
          items={props.allItems.filter((it) => props.items.indexOf(it) === -1)}
          noLabel
          placeholder={props.placeholder}
          styleClasses={{
            trigger: '!border-0 text-gray-4',
            scroll: 'max-h-[120px]'
          }}
          getItemTitle={props.getItemTitle}
          getItemValue={props.getItemValue}
          onValueChange={(value) => {
            let it = props.getItemByValue(value);
            props.onSelectItem(it)
          }}
        />
      </div>
    </div>
  )
}

export default ChipsBox;