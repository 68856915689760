import clsx from "clsx"
import { FC } from "react"

interface IProps {
    text: string,
    num: number
}

const SmallStep: FC<IProps> = (props: IProps) => {
	return (
		<div className={clsx("flex flex-col relative")}>
			<p className={clsx("absolute text-accent text-xs text-center font-medium w-[40px] -left-12 top-[2px] sm:-top-6 sm:-left-[62px] sm:w-[144px]")}>Шаг {props.num}</p>
			<span className="h-4 w-4 rounded-full border-[2px] border-accent" />
			<p className="absolute text-gray-3 text-sm left-6 w-[144px] sm:-bottom-8 sm:-left-[62px] sm:text-center font-medium">{props.text}</p>
		</div>)
}

export default SmallStep
