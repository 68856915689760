import { ISvg } from "../interfaces/ISvg";

function CrossIcon({size = 24, className}: ISvg) {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.2551 3.2551C3.59523 2.91497 4.1467 2.91497 4.48684 3.2551L12 10.7683L19.5132 3.2551C19.8533 2.91497 20.4048 2.91497 20.7449 3.2551C21.085 3.59523 21.085 4.1467 20.7449 4.48684L13.2317 12L20.7449 19.5132C21.085 19.8533 21.085 20.4048 20.7449 20.7449C20.4048 21.085 19.8533 21.085 19.5132 20.7449L12 13.2317L4.48684 20.7449C4.1467 21.085 3.59523 21.085 3.2551 20.7449C2.91497 20.4048 2.91497 19.8533 3.2551 19.5132L10.7683 12L3.2551 4.48684C2.91497 4.1467 2.91497 3.59523 3.2551 3.2551Z" 
      fill="cyrrentColor" />
    </svg>
  );
}

export default CrossIcon;