import { ISvg } from "../interfaces/ISvg";

export default function CopyIcon({ size = 24, className }: ISvg) {
	return (
		<svg
			width={size}
			height={size}
			className={className}
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M4.16675 5V16.6667C4.16675 16.8877 4.25455 17.0996 4.41083 17.2559C4.56711 17.4122 4.77907 17.5 5.00008 17.5H13.3334M15.8334 5V14.1667H7.50008V2.5H13.3334L15.8334 5Z'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
