import { P, getCurrentUrl, requestDelete, requestPost, requestPut } from "../../requester"
import {CreateUserRequest, EditUserRequest, GetUsersRequest, GetUsersResponse } from "./usersInterfaces"


export function CreateUser(args: CreateUserRequest) {
    const currentUrl = `/${P.User.split("/")[1]}/${P.User.split("/")[2]}`
    return requestPost<null>(currentUrl, args)
}

export function EditUser(userId: string, args: EditUserRequest) {
    const url = getCurrentUrl(P.User, userId)
    return requestPut<null>(url, args)
}

export function DeleteUser(userId: string) {
    const url = getCurrentUrl(P.User, userId)
    return requestDelete<null>(url)
}

export function GetUsers(args: GetUsersRequest) {
    return requestPost<GetUsersResponse>(P.Users, args)
}