import { useEffect, useState } from "react";
import Button from "../../../../UI/buttons/Button";
import clsx from "clsx";
import CustomRadioGroup from "../../../../UI/buttons/CustomRadioGroup";
import { EExportType, EReportDataType, EReportDate, EReportPartnersExportType, EReportType, IReport, ReportDates } from "../AdminAnalytics/interfaces/IAdminAnalytics";
import TextBoxDefault from "../../../../UI/inputs/textInput/TextBoxDefault";
import { format } from "date-fns";
import DateIcon from "../../../../../assets/icons/DateIcon";
import ChipsBox from "../../../../UI/inputs/ChipsBox";
import { GetPayload } from "../../../../../API/base/payloadApi/payloadFunctions";
import { ResponseContainer } from "../../../../../API/BaseApi";
import { GetPayloadResponse } from "../../../../../API/base/payloadApi/payloadInterfaces";
import { IPartner } from "../../../../../assets/interfaces/IPartner";
import Calendar from "../../../../UI/calendar/Calendar";
import { toast } from "react-toastify";
import LoaderModal from "../../../../UI/loader/LoaderModal";
import { ILogsRequest } from "../../../../../API/admin/logsApi/logsInterfaces";
import { DownloadLogs } from "../../../../../API/admin/logsApi/logsFunction";


type stateType = 'start' | 'end' | 'none'

const AdminLogs = () => {
  const [type, setType] = useState<stateType>('none');
  const [partners, setPartners] = useState<IPartner[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [report, setReport] = useState<IReport>({
    type: EReportType.Partners,
    period: EReportDate.Week,
    format: EExportType.XLSX,
    reportDataType: EReportDataType.All,
    selectType: EReportPartnersExportType.All,
  });

  const getPartnerById = (id: string) => {
    return partners.find((item) => item.id === id)
  }

  const onGetPayload = () => {
    GetPayload({ partners: true }).then((r: ResponseContainer<GetPayloadResponse>) => {
      setPartners(r.data?.partners || [])
    })
  }

  const onDownloadLogs = async () => {
    const logRequest: ILogsRequest = {
      period: report.period,
      selectedPartners: report.selected?.length ? report.selected : undefined,
      date: report.period === EReportDate.Custom && report.date?.start && report.date?.end
        ? {
          start: report.date.start,
          end: report.date.end
        }
        : undefined
    };

    setIsLoading(true);

    await DownloadLogs(logRequest, report.format === EExportType.XLSX ? EExportType.XLSX : EExportType.CSV, (errorMsg: string) => toast.error(errorMsg))

    setIsLoading(false);
  }

  const handleDateSelect = (selectDate: Date, type: stateType) => {
    if (type === 'start') {
      setReport({ ...report, period: EReportDate.Custom, date: { ...report.date, start: selectDate } });
    } else if (type === 'end') {
      setReport({ ...report, period: EReportDate.Custom, date: { ...report.date, end: selectDate } });
    }
  };

  useEffect(() => {
    onGetPayload()
  }, [])

  return (
    <div className="flex flex-col p-10 overflow-y-auto">
      <div className='flex flex-col mb-10'>
        <div className='w-full flex gap-6'>
          <CustomRadioGroup
            label='Период'
            items={ReportDates}
            value={report.period}
            getItemTitle={(item) => item.label}
            getItemValue={(item) => item.value}
            onValueChange={(value) => {
              if (value !== EReportDate.Custom) {
                setReport({ ...report, period: value as EReportDate, date: undefined })
              } else {
                setReport({ ...report, period: value as EReportDate })
              }
            }}
            styleClasses={{
              root: '!min-h-[48px]',
              item: 'h-full'
            }}
          />
          {report.period === EReportDate.Custom && <div className='flex gap-2'>
            <span className='flex flex-col relative'  >
              <TextBoxDefault
                error={!report.date?.start}
                description="Выберите дату"
                label='Дата начала'
                value={report.date?.start ? String(format(report.date?.start, 'dd.MM.yy')) : ''}
                className='max-w-[130px]'
                icon={<DateIcon className='text-gray-3 hover:text-accent' />}
                iconAnchor='right'
                onIconClick={() => type === 'start' ? setType('start') : setType('start')}
              />
              {type === 'start' && <div onMouseLeave={(e) => { setType('none') }} className={clsx('absolute bg-white shadow-2 top-[68px] flex items-center justify-center z-50')}>
                <Calendar selectDate={(date: Date) => { handleDateSelect(date, 'start') }} />
              </div>}
            </span>
            <span className='flex flex-col relative'  >
              <TextBoxDefault
                error={!report.date?.end}
                label='Дата окончания'
                description="Выберите дату"
                className='max-w-[130px]'
                value={report.date?.end ? String(format(report.date?.end, 'dd.MM.yy')) : ''}
                icon={<DateIcon className='text-gray-3 hover:text-accent' />}
                iconAnchor='right'
                onIconClick={() => { setType('end') }}
              />
              {type === 'end' && <div onMouseLeave={(e) => { setType('none') }} className={clsx('absolute bg-white shadow-2 top-[68px] flex items-center justify-center z-50')}>
                <Calendar selectDate={(date: Date) => { handleDateSelect(date, 'end') }} />
              </div>}
            </span>
          </div>}
        </div>
      </div>
      <div className='flex flex-col mb-10'>
      </div>
      {report.type === EReportType.Partners &&
        <div className="flex items-center mb-10 gap-6">
          <CustomRadioGroup
            label='Объекты экспорта'
            value={report.selectType}
            items={[
              { value: EReportPartnersExportType.All, label: 'Все' },
              { value: EReportPartnersExportType.Selected, label: 'Выбранные' },
            ]}
            getItemTitle={(item) => item.label}
            getItemValue={(item) => item.value}
            onValueChange={(value) => setReport({ ...report, selectType: value as EReportPartnersExportType })}
          />
          {report.selectType === EReportPartnersExportType.Selected && ((
            <ChipsBox
              label='Партнеры'
              placeholder='Начните вводить название партнера'
              items={report.selected || []}
              allItems={partners ? partners.map(it => it.id) : []}
              getItemTitle={(item) => getPartnerById(item)?.name || ''}
              getItemValue={(item) => getPartnerById(item)?.id || ''}
              getItemByValue={(item) => getPartnerById(item)?.id || ''}
              onDeleteItem={(item) => {
                if (!report.selected) return
                let ind = report.selected.indexOf(item)
                if (ind !== -1) {
                  setReport({ ...report, selected: [...report.selected.slice(0, ind), ...report.selected.slice(ind + 1)] })
                }
              }
              }
              onSelectItem={(item) => {
                if (!report.selected) report.selected = []
                setReport({ ...report, selected: [...report.selected, item] })
              }}
            />
          ))}
        </div>
      }
      <div className="flex gap-4 mt-14">
        <Button onClick={onDownloadLogs} noFish variant="secondary" rounded="big" className="w-fit">Скачать логи</Button>
      </div>
      <LoaderModal open={isLoading} />
    </div>
  )
}


export default AdminLogs;

