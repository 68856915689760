import { ISvg } from "../interfaces/ISvg";

function ArrowsIcon({ size = 24, className }: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4993 10.8333H9.16602V7.5" 
      stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.8333 11.5753V16.667C15.8333 16.888 15.7455 17.1 15.5893 17.2562C15.433 17.4125 15.221 17.5003 15 17.5003H3.33333C3.11232 17.5003 2.90036 17.4125 2.74408 17.2562C2.5878 17.1 2.5 16.888 2.5 16.667V5.00033C2.5 4.77931 2.5878 4.56735 2.74408 4.41107C2.90036 4.25479 3.11232 4.16699 3.33333 4.16699H8.425" 
      stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.4993 2.5L9.16602 10.8333" 
      stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowsIcon;