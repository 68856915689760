export const scrollListTop = (listId: string) => {
    document.getElementById(`${listId}`)?.scrollTo(0,0);
}

export const getRandomInt = (min: number, max: number) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min);
};

export const getRandomId = (prefix: string) => {
	return prefix + getRandomInt(10000, 100000);
};