import { ISvg } from "../interfaces/ISvg";

function VkIcon({ size = 24, className }: ISvg) {
    return (
        <svg width={size} height={size} className={className} viewBox="0 0 21 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5181 0.812562C20.6641 0.344175 20.5181 0 19.8234 0H17.526C16.9418 0 16.6725 0.297317 16.5264 0.625168C16.5264 0.625168 15.3581 3.3652 13.7031 5.14504C13.1676 5.66024 12.9242 5.82417 12.6321 5.82417C12.4861 5.82417 12.2747 5.66024 12.2747 5.1919V0.812562C12.2747 0.250497 12.1052 0 11.6183 0H8.00812C7.64307 0 7.42353 0.260867 7.42353 0.508101C7.42353 1.04093 8.25102 1.1638 8.33632 2.66264V5.91789C8.33632 6.63159 8.20236 6.76098 7.91028 6.76098C7.13145 6.76098 5.23694 4.00871 4.11332 0.859382C3.89311 0.247262 3.67225 0 3.0851 0H0.787691C0.131288 0 0 0.297317 0 0.625168C0 1.21066 0.778874 4.11462 3.62656 7.95532C5.525 10.5782 8.19979 12 10.6337 12C12.0941 12 12.2747 11.6842 12.2747 11.1403V9.15791C12.2747 8.52633 12.4131 8.40028 12.8755 8.40028C13.2163 8.40028 13.8004 8.56421 15.1634 9.82883C16.7212 11.3276 16.978 12 17.8542 12H20.1516C20.808 12 21.1362 11.6842 20.9468 11.061C20.7396 10.4399 19.9959 9.53877 19.0091 8.47053C18.4736 7.86165 17.6704 7.20594 17.427 6.87801C17.0862 6.45652 17.1836 6.26913 17.427 5.89446C17.427 5.89446 20.226 2.10058 20.5181 0.812562Z" fill="currentColor" />
        </svg>
    );
}

export default VkIcon;