import { ISvg } from "../interfaces/ISvg";

function EyeClosedIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.58358 9.36276C3.32332 9.04052 2.85111 8.99029 2.52887 9.25055C2.20664 9.51082 2.1564 9.98303 2.41667 10.3053C2.88503 10.8851 3.467 11.512 4.17024 12.1131L2.34265 15.2815C2.13568 15.6403 2.25877 16.0989 2.61757 16.3059C2.97637 16.5129 3.43501 16.3898 3.64198 16.031L5.3756 13.0255C6.31232 13.6491 7.40772 14.1916 8.67272 14.5493L8.12863 17.6453C8.05694 18.0532 8.32953 18.4421 8.7375 18.5138C9.14546 18.5855 9.5343 18.3129 9.60599 17.9049L10.1404 14.8641C10.729 14.9518 11.3486 14.9996 12.0001 14.9996C12.6461 14.9996 13.2608 14.9527 13.8449 14.8664L14.3803 17.9051C14.4521 18.313 14.8411 18.5855 15.249 18.5136C15.6569 18.4417 15.9294 18.0528 15.8575 17.6448L15.3129 14.5535C16.5817 14.1965 17.6802 13.6535 18.6194 13.029L20.3497 16.0136C20.5575 16.372 21.0164 16.4941 21.3747 16.2863C21.7331 16.0786 21.8552 15.6197 21.6474 15.2613L19.8248 12.1176C20.5304 11.5151 21.1141 10.8866 21.5836 10.3053C21.8438 9.98303 21.7936 9.51082 21.4714 9.25055C21.1491 8.99029 20.6769 9.04052 20.4167 9.36276C19.9107 9.98917 19.2666 10.6664 18.4713 11.288C18.4165 11.3202 18.3673 11.3583 18.324 11.4011C16.7874 12.5612 14.7087 13.4996 12.0001 13.4996C9.69045 13.4996 7.46511 12.771 5.61666 11.3558C5.60395 11.3454 5.59083 11.3353 5.57731 11.3256C4.75969 10.6938 4.09974 10.0018 3.58358 9.36276Z" 
      fill="currentColor" />
    </svg>
  );
}

export default EyeClosedIcon;