import * as Label from '@radix-ui/react-label';
import * as SwitchBase from '@radix-ui/react-switch';
import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { getRandomId } from '../../../utils/reusedFunks';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	label?: string;
	checked?: boolean;
	onCheckedChange?(checked: boolean): void;

	classes?: {
		containerClasses?: string;
		rootClasses?: string;
		thumbClasses?: string;
	};
}

const Switch = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
	const id = getRandomId('sw');
	return (
		<div
			ref={ref}
			className={clsx(
				'inline-flex items-center gap-2',
				props.classes?.containerClasses
			)}
		>
			<SwitchBase.Root
				id={id}
				className={clsx(
					'h-5 w-8 cursor-pointer rounded-full border bg-gray-3 border-gray-3 outline-none',
					'data-[state=checked]:border-accent data-[state=checked]:bg-accent',
					'disabled:data-[state=checked]:border-gray-2 disabled:data-[state=checked]:bg-gray-2',
					'disabled:data-[state=unchecked]:border-gray-2',
					props.classes?.rootClasses
				)}
				checked={props.checked}
				onCheckedChange={props.onCheckedChange}
				{...props}
			>
				<SwitchBase.Thumb
					className={clsx(
						'block h-3.5 w-3.5 translate-x-0.5 rounded-full bg-white text-white transition-all',
						'data-[state=checked]:translate-x-[14px] data-[state=unchecked]:bg-white',
						'data-[disabled]:data-[state=unchecked]:bg-gray-2',
						props.classes?.thumbClasses
					)}
				/>
			</SwitchBase.Root>
			{props.label && (
				<Label.Root className='text-sm' htmlFor={id}>
					{props.label}
				</Label.Root>
			)}
		</div>
	);
});

export default Switch;
