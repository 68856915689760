import { ISvg } from "../interfaces/ISvg";

function SearchIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.69433 3.55611C6.96881 2.70453 8.4672 2.25 10 2.25C12.0554 2.25 14.0267 3.06652 15.4801 4.51992C16.9335 5.97333 17.75 7.94457 17.75 10C17.75 11.5328 17.2955 13.0312 16.4439 14.3057C16.3007 14.5199 16.1474 14.7263 15.9847 14.924L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L14.924 15.9847C14.3361 16.4684 13.6769 16.8655 12.9658 17.1601C11.5497 17.7466 9.99141 17.9001 8.48805 17.6011C6.9847 17.3021 5.60378 16.5639 4.51993 15.4801C3.43607 14.3962 2.69795 13.0153 2.39892 11.5119C2.09988 10.0086 2.25336 8.45033 2.83994 7.0342C3.42652 5.61808 4.41985 4.40769 5.69433 3.55611ZM10 3.75C8.76387 3.75 7.5555 4.11656 6.52769 4.80331C5.49988 5.49007 4.6988 6.46619 4.22576 7.60823C3.75271 8.75027 3.62894 10.0069 3.8701 11.2193C4.11125 12.4317 4.70651 13.5453 5.58059 14.4194C6.45466 15.2935 7.56831 15.8888 8.78069 16.1299C9.99307 16.3711 11.2497 16.2473 12.3918 15.7742C13.5338 15.3012 14.5099 14.5001 15.1967 13.4723C15.8834 12.4445 16.25 11.2361 16.25 10C16.25 8.3424 15.5915 6.75268 14.4194 5.58058C13.2473 4.40848 11.6576 3.75 10 3.75Z" 
      fill="currentColor" />
    </svg>
  );
}

export default SearchIcon;