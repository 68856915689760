import { ISvg } from "../interfaces/ISvg";

function PencilIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.4739 4.53543C19.215 4.30593 18.877 4.18526 18.5307 4.19935C18.1761 4.21378 17.8417 4.36827 17.6009 4.62893C17.5942 4.63619 17.5873 4.64332 17.5803 4.65031L8.83598 13.3947L8.10449 15.8955L10.6064 15.1637L19.3512 6.46816C19.3606 6.4588 19.3702 6.44969 19.3801 6.44085C19.514 6.32117 19.6224 6.17581 19.699 6.0134C19.7756 5.85099 19.8188 5.67484 19.826 5.49542C19.8331 5.316 19.8042 5.13697 19.7408 4.96896C19.6803 4.80865 19.5897 4.6615 19.4739 4.53543ZM18.4697 2.70059C19.2212 2.67002 19.9542 2.93881 20.5077 3.44797C20.5198 3.45911 20.5316 3.47065 20.5429 3.48256C20.8055 3.75806 21.01 4.08351 21.1443 4.43959C21.2786 4.79567 21.34 5.17511 21.3248 5.55537C21.3095 5.93564 21.2181 6.30896 21.0557 6.65318C20.8965 6.99093 20.6722 7.29389 20.3958 7.54477L11.5288 16.3618C11.4401 16.4501 11.3307 16.5147 11.2106 16.5498L7.21055 17.7198C6.94752 17.7968 6.66346 17.7241 6.46967 17.5303C6.27588 17.3365 6.20322 17.0525 6.28016 16.7894L7.45016 12.7894C7.4855 12.6686 7.55067 12.5587 7.63967 12.4697L16.51 3.59935C17.0195 3.05397 17.7235 2.73094 18.4697 2.70059ZM2.25 21C2.25 20.5858 2.58579 20.25 3 20.25H21C21.4142 20.25 21.75 20.5858 21.75 21C21.75 21.4142 21.4142 21.75 21 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21Z" 
      fill="currentColor" />
    </svg>
  );
}

export default PencilIcon;