import { ISvg } from "../interfaces/ISvg";

function InfoIcon({size = 24, className}: ISvg) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.25 10.5293C10.8358 10.5293 10.5 10.8651 10.5 11.2793C10.5 11.6935 10.8358 12.0293 11.25 12.0293V16.5293C11.25 16.9435 11.5858 17.2793 12 17.2793H12.75C13.1642 17.2793 13.5 16.9435 13.5 16.5293C13.5 16.1151 13.1642 15.7793 12.75 15.7793V11.2793C12.75 10.8651 12.4142 10.5293 12 10.5293H11.25Z" 
      fill="currentColor" />
      <path d="M11.8125 9.0293C12.4338 9.0293 12.9375 8.52562 12.9375 7.9043C12.9375 7.28298 12.4338 6.7793 11.8125 6.7793C11.1912 6.7793 10.6875 7.28298 10.6875 7.9043C10.6875 8.52562 11.1912 9.0293 11.8125 9.0293Z" 
      fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.25 12.0293C2.25 6.64452 6.61522 2.2793 12 2.2793C17.3848 2.2793 21.75 6.64452 21.75 12.0293C21.75 17.4141 17.3848 21.7793 12 21.7793C6.61522 21.7793 2.25 17.4141 2.25 12.0293ZM3.75 12.0293C3.75 7.47295 7.44365 3.7793 12 3.7793C16.5563 3.7793 20.25 7.47295 20.25 12.0293C20.25 16.5856 16.5563 20.2793 12 20.2793C7.44365 20.2793 3.75 16.5856 3.75 12.0293Z" 
      fill="currentColor" />
    </svg>
  );
}

export default InfoIcon;