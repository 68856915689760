import clsx from "clsx";
import {type ButtonHTMLAttributes, forwardRef, type ReactNode} from "react";
import FishIcon from "../../../assets/icons/FishIcon";

type buttonType = "primary" | "secondary";
type buttonSize = "big";

const typeClasses = {
	primary:
		"text-accent border-transparent bg-transparent enabled:hover:text-accent-hover disabled:text-gray-2 focus:text-accent-hover",
	secondary:
		"text-gray-3 border-transparent bg-transparent enabled:hover:text-accent disabled:text-gray-2 focus:text-accent",
};

const sizeClasses = {
	big: "h-5",
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
	size?: buttonSize;
	variant?: buttonType;
	noFish?: boolean;
}

const LinkButton = forwardRef<HTMLButtonElement, Props>(
	(
		{size = "big", variant = "primary", noFish, children, className, ...props}: Props,
		ref,
	) => {
		return (
			<button
				ref={ref}
				className={clsx(
					"flex cursor-pointer items-center justify-center gap-1 rounded-lg text-center text-sm",
					"font-normal outline-none transition-all disabled:cursor-default",
					sizeClasses[size],
					typeClasses[variant],
					className,
				)}
				{...props}
			>
				{!noFish && <FishIcon size={20} />}
				{children}
				{!noFish && <FishIcon size={20} />}
			</button>
		);
	},
);

LinkButton.displayName = "LinkButton";

export default LinkButton;
