export const checkInn = (inn: string) => {
  const isValid = /^(([0-9]{12})|([0-9]{10}))?$/.test(inn);
  return isValid
};
export const checkEmail = (email: string) => {
  return email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
};

export const checkIsNumber = (str: string) => /^\d*$/.test(str)

export const checkLogin = (str: string) => /^[a-z0-9\-_\.]+$/.test(str);
